import React, { useEffect, useState } from "react"
// import CouponProductList from '../../components/Coupon/ProductList'
import CouponGroupList from '../../components/Coupon/GroupList'
import CreateGroup from '../../components/Coupon/CreateGroup'
import { getGroupes } from '../../services/coupon.service'
import {
    Container,
    Table
} from "reactstrap"

const Coupon = props => {
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)

    const refreshGroupes = async () => {
        console.log('Refreshing groupes')
        try {
            setLoading(true)
            let data = await getGroupes()
            setProducts(data.data)
        } catch (err) {
            console.log(err)
            setProducts([])
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        refreshGroupes()
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <CouponGroupList products={products} loading={loading} refreshGroupes={refreshGroupes} setLoading={setLoading} />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Coupon
