import React from 'react'
import IPPSservice from 'services/IPPSservice'
import ReactForm from 'ua-react-form'
const { List } = ReactForm

const DataList = ({ datas }) => {
    const fields = [
        { name: 'Сегмэнт', fieldName: 'body.segment' },
        { name: 'Гарчиг', fieldName: 'body.title' },
        { name: 'Дэд гарчиг', fieldName: 'body.sub_title' },
        { name: 'Текст', fieldName: 'body.body' },
        { name: 'Дарахад хийх үйлдэл', fieldName: 'body.click_action' },
        { name: 'Явсан тоо', fieldName: 'processed' },
        { name: 'Яваагүй тоо', fieldName: 'failed' },
        { name: 'Явж эхлэх огноо', fieldName: 'send_by' },
        { name: 'Төлөв', fieldName: 'status' }
    ]

    const buttons = [
        {
            color: 'success',
            onClick: () => {
                window.location.href += '/create'
            },
            label: 'Create'
        }
    ]

    const onDelete = async ({ id }) => {
        const result = await IPPSservice.deleteIPPS('notification', id)
        console.log('Delete Result: ', result)
    }

    return (
        <List
            fields={fields}
            title={'Notifications'}
            width={12}
            datas={datas}
            buttons={buttons}
            editId="_id"
            Delete={{
                fieldName: 'body.title',
                onDelete: onDelete
            }}
        ></List>
    )
}

export default DataList
