import React, { useEffect, useState, useContext } from 'react'
import { useParams, Link } from 'react-router-dom'
import IPPSservice from 'services/IPPSservice'
import { upload } from '../../services/mcp.service'
import ReactForm from 'ua-react-form'
import AppContext from '../../store/AppContext'
const { Update } = ReactForm
import _ from 'lodash'

const BadgeDetail = () => {
    const { id } = useParams()
    const [context, setContext] = useState({ body: {} })

    const onChange = ({ target }) => {
        let con = context
        _.set(con, target.name, target.value)
        setContext(con)
        console.log(con)
    }

    const onSubmit = async event => {
        try {
            event.preventDefault()
            const result = await IPPSservice.updateIPPS('campaign', id, context)
            console.log(result)
        } catch (err) {
            console.log(err)
        }
    }

    const getData = async () => {
        try {
            let data = await IPPSservice.getOneIPPS('campaign', id)
            console.log('Campaign Detail: ', data)
            setContext(data)
        } catch (err) {
            console.log(err)
            setContext({})
        }
    }

    useEffect(async () => {
        await getData()
    }, [])

    const title = 'Update Campaign'

    const fields = [
        { type: 'text', onChange: onChange, name: 'Нэр', fieldName: 'name' },
        {
            type: 'text',
            onChange: onChange,
            name: 'Монгол нэр',
            fieldName: 'name_mn'
        },
        {
            type: 'text',
            onChange: onChange,
            name: 'Тайлбар',
            fieldName: 'description'
        },
        {
            type: 'text',
            onChange: onChange,
            name: 'Идэвхитэй эсэх',
            fieldName: 'is_active',
            type: 'boolean'
        },
        {
            type: 'datetime-local',
            onChange: onChange,
            name: 'Эхлэх хугацаа',
            fieldName: 'start_at'
        },
        {
            type: 'datetime-local',
            onChange: onChange,
            name: 'Дуусах хугацаа',
            fieldName: 'end_at'
        }
    ]

    return (
        <div className="page-content">
            <Update
                fields={fields}
                title={title}
                context={context}
                onSubmit={onSubmit}
            ></Update>
        </div>
    )
}

export default BadgeDetail
