import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

//i18n
import { withTranslation } from 'react-i18next'
import SidebarContent from './SidebarContent'

import { Link } from 'react-router-dom'

const Sidebar = props => {
    return (
        <React.Fragment>
            <div className="vertical-menu">
                <div className="navbar-brand-box">
                    <Link to="/">
                        <p
                            style={{
                                fontFamily: 'Mochiy Pop P One',
                                fontSize: '2.5em',
                                color: '#fff',
                                marginTop: '5%'
                            }}
                        >
                            <img
                                width={33}
                                height={33}
                                src={
                                    'https://upoint.mn/app_assets/static/upoint.png'
                                }
                            ></img>
                            {' CMS'}
                        </p>
                    </Link>
                </div>
                <div data-simplebar className="h-100">
                    <SidebarContent />
                    {/* {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />} */}
                </div>
                <div className="sidebar-background"></div>
            </div>
        </React.Fragment>
    )
}
export default Sidebar
