import React, { useContext } from 'react'
import { Card, CardBody, Col, Row, CardTitle, Table, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Loading } from '../Common'
import ConfirmModal from '../Modal/ConfirmModal'
import { deleteGroup } from '../../services/coupon.service'
import AppContext from '../../store/AppContext'

const ProductGroupList = ({
    products,
    loading,
    refreshGroupes,
    setLoading
}) => {
    const { notify } = useContext(AppContext)
    const styleGreen = { color: 'green' }
    const styleRed = { color: 'red' }
    const onDeleteConfirm = async function ({ group_id }) {
        try {
            setLoading(true)
            console.log(`Deleting group ${group_id}`)
            const result = await deleteGroup(group_id)
            await refreshGroupes()
            notify('tr', 'success', `${group_id} id тай групп устгагдлаа.`)
            console.log(result)
        } catch (err) {
            notify('tr', 'danger', `Групп устгахад алдаа гарлаа.`)
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md="11">
                                    <CardTitle>
                                        <h3>
                                            <b>Группууд</b>
                                        </h3>
                                    </CardTitle>
                                </Col>
                                <Col md="1">
                                    <Button
                                        color="success"
                                        onClick={() => {
                                            window.location.href =
                                                '/coupon/create'
                                        }}
                                    >
                                        Үүсгэх
                                    </Button>
                                </Col>
                            </Row>
                            <Loading loading={loading} />
                            <div className="table-responsive">
                                <Table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>Зураг</th>
                                            <th>Нэр</th>
                                            <th>Merchant</th>
                                            <th>Presale price</th>
                                            <th>Cash Price</th>
                                            <th>Point Price</th>
                                            <th>is active</th>
                                            <th>is show</th>
                                            <th>is single</th>
                                            <th>Устгах</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products.map(product => (
                                            <tr key={product._id}>
                                                <td>
                                                    <Link
                                                        to={`/coupon/${product._id}`}
                                                    >
                                                        <img
                                                            src={
                                                                product.thumbnail
                                                            }
                                                            className="img-fluid img-thumbnail"
                                                            height="80px"
                                                            width="80px"
                                                        />
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link
                                                        to={`/coupon/${product._id}`}
                                                    >
                                                        {product.name}
                                                    </Link>
                                                </td>
                                                <td>{product.merchant}</td>
                                                <td>{product.presale_price}</td>
                                                <td>{product.cash_price}</td>
                                                <td>{product.point_price}</td>
                                                <td
                                                    style={
                                                        product.is_active
                                                            ? styleGreen
                                                            : styleRed
                                                    }
                                                >
                                                    {product.is_active
                                                        ? 'Тийм'
                                                        : 'Үгүй'}
                                                </td>
                                                <td
                                                    style={
                                                        product.is_show
                                                            ? styleGreen
                                                            : styleRed
                                                    }
                                                >
                                                    {product.is_show
                                                        ? 'Тийм'
                                                        : 'Үгүй'}
                                                </td>
                                                <td
                                                    style={
                                                        product.is_single
                                                            ? styleGreen
                                                            : styleRed
                                                    }
                                                >
                                                    {product.is_single
                                                        ? 'Тийм'
                                                        : 'Үгүй'}
                                                </td>
                                                <td>
                                                    <ConfirmModal
                                                        title={
                                                            'Бүтээгдэхүүний групп устгах'
                                                        }
                                                        body={`Та ${product.name} группыг устгахдаа итгэлтэй байна уу? `}
                                                        confirmText={'Устгах'}
                                                        onConfirm={
                                                            onDeleteConfirm
                                                        }
                                                        params={{
                                                            group_id:
                                                                product._id
                                                        }}
                                                    />
                                                    {/* <a style={{ color: 'red' }}><i class="bx bx-trash"></i> Устгах</a> */}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ProductGroupList
