import React, { useEffect, useState, useContext } from 'react'
import { useParams, Link } from 'react-router-dom'
import { upload } from '../../services/mcp.service'
import { list } from '../../services/mcp.service'
import {
    getReward,
    updateReward,
    createReward
} from '../../services/reward.service'
import { formatDatePicker } from '../../helpers/date_helper'
import { getTransactions } from '../../services/transaction.service'
import {
    Row,
    Container,
    Button,
    Card,
    CardBody,
    Col,
    CardTitle,
    Input
} from 'reactstrap'

import Select from 'react-select'
import AppContext from '../../store/AppContext'
import { entries } from 'lodash'

const CreateReward = () => {
    const { notify, merchants, segments } = useContext(AppContext)

    const [state, setState] = useState({
        segment: '',
        name: '',
        short_code: '',
        type: 'nothing',
        is_active: false,
        start_at: null,
        end_at: null,
        point: 0,
        transaction_config: ''
    })

    const [transactions, setTransactions] = useState([])

    const onSubmit = async () => {
        try {
            let result = await createReward(state)
            notify('tr', 'success', `Reward амжилттай үүсэглээ.`)
            window.location.href = '/reward'
        } catch (err) {
            notify('tr', 'danger', `Алдаа гарлаа. ${err.message}`)
            console.log(err)
        }
    }

    const onChange = evt => {
        const value = evt.target.value

        if (
            evt.target.name === 'point' ||
            evt.target.name === 'spin' ||
            evt.target.name === 'coupon'
        ) {
            setState({
                ...state,
                point: 0,
                spin: '',
                coupon: ''
            })
        }

        setState({
            ...state,
            [evt.target.name]: value
        })
    }

    const onChange3 = evt => {
        const value = evt.target.value
        setState({
            ...state,
            point: 0,
            spin: null,
            coupon: null,
            type: evt.target.name,
            [evt.target.name]: value
        })
    }

    const toggleChange = evt => {
        console.log(evt.target.name)
        setState({
            ...state,
            [evt.target.name]: !state[evt.target.name]
        })
    }
    useEffect(async () => {
        const result = await getTransactions()
        setTransactions(
            result &&
                result.map(transaction => {
                    return {
                        label: transaction.name,
                        value: transaction
                    }
                })
        )
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="page-title-box d-block align-items-center justify-content-between">
                        <div className="page-title-right">
                            <nav className="">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/reward">
                                            <h2>Rewards</h2>
                                        </Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4">
                                        Reward дэлгэрэнгүй
                                    </CardTitle>
                                    <Row>
                                        <Col md="6" sm="12">
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Нэр
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        placeholder="Нэр"
                                                        type="text"
                                                        value={state.name}
                                                        name="name"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>

                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Код
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Код"
                                                        value={state.short_code}
                                                        name="short_code"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>

                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-datetime-local-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Эхлэх огноо
                                                </label>
                                                <div className="col-md-10">
                                                    <Input
                                                        className="form-control"
                                                        type="date"
                                                        value={state.start_at}
                                                        name="start_at"
                                                        onChange={onChange}
                                                        id="example-datetime-local-input"
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-datetime-local-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Дуусах огноо
                                                </label>
                                                <div className="col-md-10">
                                                    <Input
                                                        className="form-control"
                                                        type="date"
                                                        value={state.end_at}
                                                        name="end_at"
                                                        onChange={onChange}
                                                        id="example-datetime-local-input"
                                                    />
                                                </div>
                                            </Row>
                                            <Button
                                                color="primary"
                                                type="submit"
                                                onClick={onSubmit}
                                            >
                                                Хадгалах
                                            </Button>
                                        </Col>
                                        <Col md="6">
                                            <Row className="mb-3">
                                                <Input
                                                    name="segment"
                                                    type="select"
                                                    onChange={onChange}
                                                >
                                                    <option id={-1} value={-1}>
                                                        Segment сонгоно уу!
                                                    </option>
                                                    {segments.map(
                                                        (segment, id) => {
                                                            return (
                                                                <option
                                                                    id={id}
                                                                    value={
                                                                        segment.value
                                                                    }
                                                                >
                                                                    {
                                                                        segment.label
                                                                    }
                                                                </option>
                                                            )
                                                        }
                                                    )}
                                                </Input>
                                            </Row>
                                            <Row className="mb-3">
                                                <Input
                                                    name="transaction_config"
                                                    type="select"
                                                    onChange={onChange}
                                                >
                                                    <option id={-1} value={-1}>
                                                        Transaction config
                                                        сонгох
                                                    </option>
                                                    {transactions.map(
                                                        (transaction, id) => {
                                                            return (
                                                                <option
                                                                    id={id}
                                                                    value={
                                                                        transaction.value
                                                                    }
                                                                >
                                                                    {
                                                                        transaction.label
                                                                    }
                                                                </option>
                                                            )
                                                        }
                                                    )}
                                                </Input>
                                            </Row>
                                            <Row className="mb-3">
                                                <Input
                                                    name="type"
                                                    type="select"
                                                    onChange={onChange}
                                                >
                                                    <option id={-1} value={-1}>
                                                        Төрөл сонгох
                                                    </option>
                                                    <option
                                                        id={0}
                                                        value="point"
                                                    >
                                                        Point
                                                    </option>
                                                    <option
                                                        id={1}
                                                        value="coupon"
                                                    >
                                                        Coupon
                                                    </option>
                                                    <option id={2} value="spin">
                                                        Spin
                                                    </option>
                                                </Input>
                                            </Row>
                                            {state.type === 'point' ? (
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label"
                                                    >
                                                        Point
                                                    </label>
                                                    <div className="col-md-10">
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            value={state.point}
                                                            name="point"
                                                            onChange={onChange3}
                                                        />
                                                    </div>
                                                </Row>
                                            ) : (
                                                <></>
                                            )}

                                            {state.type === 'coupon' ? (
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label"
                                                    >
                                                        Coupon
                                                    </label>
                                                    <div className="col-md-10">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={state.coupon}
                                                            name="coupon"
                                                            onChange={onChange3}
                                                        />
                                                    </div>
                                                </Row>
                                            ) : (
                                                <></>
                                            )}

                                            {state.type === 'spin' ? (
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label"
                                                    >
                                                        Spin
                                                    </label>
                                                    <div className="col-md-10">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={state.spin}
                                                            name="spin"
                                                            onChange={onChange3}
                                                        />
                                                    </div>
                                                </Row>
                                            ) : (
                                                <></>
                                            )}

                                            <Row className="mb-3">
                                                <Row className="mb-3"></Row>
                                                <div className="form-check form-check-primary mb-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_active"
                                                        id="isActiveCheck"
                                                        checked={
                                                            state.is_active
                                                        }
                                                        onChange={toggleChange}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="isActiveCheck"
                                                    >
                                                        Идэвхитэй эсэх
                                                    </label>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreateReward
