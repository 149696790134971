import { post, get, del, put } from '../helpers/ipps_api_helper'

// Groupes

export async function getGroupes() {
    try {
        let result = await get('/coupon/group')
        return result
    } catch (err) {
        throw err
    }
}

export async function getGroup(groupId) {
    try {
        let result = await get(`/coupon/group/${groupId}`)
        return result
    } catch (err) {
        throw err
    }
}

export async function createGroup(body) {
    try {
        let result = await post('/coupon/group', body)
        return result
    } catch (err) {
        throw err
    }
}

export async function updateGroup(groupId, body) {
    try {
        let result = await put(`/coupon/group/${groupId}`, body)
        return result
    } catch (err) {
        throw err
    }
}

export async function deleteGroup(groupId) {
    try {
        let result = await del(`/coupon/group/${groupId}`)
        return result
    } catch (err) {
        throw err
    }
}

// Products

export async function getProducts(groupId) {
    try {
        let result = await get(`/coupon/product?group_id=${groupId}`)
        return result
    } catch (err) {
        throw err
    }
}

export async function getProduct(productId) {
    try {
        let result = await get(`/coupon/product/${productId}`)
        return result
    } catch (err) {
        throw err
    }
}

export async function createProduct(body) {
    try {
        let result = await post('/coupon/product', body)
        return result
    } catch (err) {
        throw err
    }
}

export async function updateProduct(productId, body) {
    try {
        let result = await post(`/coupon/product/${productId}`, body)
        return result
    } catch (err) {
        throw err
    }
}

export async function deleteProduct(productId) {
    try {
        let result = await del(`/coupon/product/${productId}`)
        return result
    } catch (err) {
        throw err
    }
}

export default {
    getProducts,
    createProduct,
    updateProduct,
    deleteProduct,

    getGroupes,
    createGroup,
    updateGroup,
    deleteGroup
}
