import { post, get, del } from '../helpers/ipps_api_helper'

// Groupes

export async function getCampaigns() {
    try {
        let result = await get('/campaign')
        return result
    } catch (err) {
        throw err
    }
}

export async function getCampaignDetail(campaignId) {
    try {
        let result = await get(`/campaign/${campaignId}`)
        return result
    } catch (err) {
        throw err
    }
}

export async function createCampaign(body) {
    try {
        let result = await post('/campaign', body)
        return result
    } catch (err) {
        throw err
    }
}

export async function updateCampaign(campaignId, body) {
    try {
        let result = await post(`/campaign/${campaignId}`, body)
        return result
    } catch (err) {
        throw err
    }
}

export async function deleteCampaign(campaignId) {
    try {
        let result = await del(`/campaign/${campaignId}`)
        return result
    } catch (err) {
        throw err
    }
}

export default {
    getCampaigns,
    createCampaign,
    updateCampaign,
    deleteCampaign
}
