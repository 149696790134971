import React, { useEffect, useState, useContext } from 'react'
import { getProducts, deleteProduct } from '../../services/coupon.service'
import { Card, CardBody, Col, Row, CardTitle, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Loading, Pagination } from '../Common'
import ConfirmModal from '../Modal/ConfirmModal'
import AppContext from '../../store/AppContext'

const CouponProductList = ({ groupId }) => {
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const styleGreen = { color: 'green' }
  const styleRed = { color: 'red' }
  const { notify } = useContext(AppContext)

  const fetchproducts = async () => {
    try {
      setLoading(true)
      let data = await getProducts(groupId)
      console.log(groupId)
      console.log(data)
      setProducts(data.data)
    } catch (err) {
      console.log(err)
      setProducts([])
    } finally {
      setLoading(false)
    }
  }

  const onDeleteConfirm = async function ({ product_id }) {
    try {
      setLoading(true)
      const result = await deleteProduct(product_id)
      await fetchproducts()
      notify('tr', 'success', `${product_id} id тай бүтээгдэхүүн устгагдлаа.`)
    } catch (err) {
      notify('tr', 'danger', `Групп устгахад алдаа гарлаа.`)
      console.log(err)
    } finally {
      setLoading(false)
    }

  }

  useEffect(() => {
    console.log(groupId)
    fetchproducts()
  }, [])

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle className="h4">
                Бүтээгдэхүүнүүд  <Link to={`/coupon/${groupId}/add_product`} className="font-16 btn-block btn btn-primary"><i class="bx bx-plus"></i></Link>
              </CardTitle>
              <Loading loading={loading} />
              <div className="table-responsive">
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th>Нэр</th>
                      <th>Нэр/EN/</th>
                      <th>Зураг</th>
                      <th>Cover</th>
                      <th>Presale Price</th>
                      <th>Cash Price</th>
                      <th>Point Price</th>
                      <th>is active</th>
                      <th>is limited</th>
                      <th>limit per user</th>
                      <th>qty</th>
                      <th>total qty</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map(product => (
                      <tr key={product._id}>
                        <td>
                          <Link
                            to={`/coupon/product/${product._id}`}
                          >
                            {product.name}
                          </Link>
                        </td>
                        <td>{product.name_en}</td>
                        <td >
                          <Link
                            to={`/coupon/product/${product._id}`}
                          >
                            <img src={product.thumbnail} height="70px" width="auto" />
                          </Link>
                        </td>
                        <td >
                          <Link
                            to={`/coupon/product/${product._id}`}
                          >
                            <img src={product.cover_image} height="70px" width="auto" />
                          </Link>
                        </td>

                        <td>{product.presale_price}</td>
                        <td>{product.cash_price}</td>
                        <td>{product.point_price}</td>
                        <td
                          style={
                            product.is_active
                              ? styleGreen
                              : styleRed
                          }
                        >
                          {product.is_active
                            ? 'Тийм'
                            : 'Үгүй'}
                        </td>
                        <td
                          style={
                            product.is_limited
                              ? styleGreen
                              : styleRed
                          }
                        >
                          {product.is_limited
                            ? 'Тийм'
                            : 'Үгүй'}
                        </td>
                        <td>{product.limit_per_user}</td>
                        <td>{product.qty}</td>
                        <td>{product.total_qty}</td>
                        <td>
                          <ConfirmModal title={'Бүтээгдэхүүний групп устгах'} body={`Та ${product.name} бүтээгдэхүүнийг устгахдаа итгэлтэй байна уу? `} confirmText={'Устгах'} onConfirm={onDeleteConfirm} params={{ product_id: product._id }} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CouponProductList
