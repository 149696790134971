import React, { useEffect, useState, useContext } from 'react'
import IPPSservice from 'services/IPPSservice'
import { upload } from '../../services/mcp.service'
import ReactForm from 'ua-react-form'
const { Create } = ReactForm
import AppContext from '../../store/AppContext'
import _ from 'lodash'

const CreateBadge = () => {
    const [context, setContext] = useState({})
    const { merchants } = useContext(AppContext)

    const onChange = ({ target }) => {
        let con = context
        _.set(con, target.name, target.value)
        setContext(con)
    }

    const onChangeImage = async ({ target }) => {
        try {
            if (target.files && target.files[0]) {
                let img = target.files[0]
                const data = new FormData()
                data.append('image', img)
                let result = await upload(data)

                if (result.status_code !== 0) {
                    throw new Error(result.status)
                }

                let con = context
                _.set(con, target.name, result.data.path)
                setContext(con)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const onSubmit = async event => {
        try {
            event.preventDefault()
            const result = await IPPSservice.createIPPS('badge', context)
            console.log(result)
        } catch (err) {
            console.error(err)
        }
    }

    const title = 'Create Badge'

    const fields = [
        {
            type: 'text',
            isRequired: true,
            name: 'Нэр',
            fieldName: 'name',
            onChange: onChange
        },
        {
            type: 'select',
            isRequired: true,
            name: 'Merchant',
            fieldName: 'merchant',
            onChange: onChange,
            options: merchants
        },
        {
            type: 'file',
            isRequired: true,
            name: 'Лого',
            fieldName: 'logo',
            onChange: onChangeImage
        },
        {
            type: 'file',
            isRequired: true,
            name: 'Идэвхигүй лого',
            fieldName: 'inactive_log',
            onChange: onChangeImage
        },
        {
            type: 'text',
            isRequired: true,
            name: 'Тайлбар',
            fieldName: 'description',
            onChange: onChange
        },
        {
            type: 'text',
            isRequired: true,
            name: 'Идэвхигүй тайлбар',
            fieldName: 'inactive_description',
            onChange: onChange
        },
        {
            type: 'text',
            isRequired: true,
            name: 'Shortcode',
            fieldName: 'shortcode',
            onChange: onChange
        },
        {
            type: 'color',
            isRequired: true,
            name: 'Background Color',
            fieldName: 'background',
            onChange: onChange
        },
        {
            type: 'number',
            isRequired: true,
            name: 'Index',
            fieldName: 'index',
            onChange: onChange
        },
        {
            type: 'checkbox',
            isRequired: true,
            name: 'Collective эсэх',
            fieldName: 'is_collective',
            onChange: onChange
        },
        {
            type: 'checkbox',
            name: 'Харагдах эсэх',
            fieldName: 'is_show',
            onChange: onChange
        },
        {
            type: 'checkbox',
            name: 'Груп эсэх',
            fieldName: 'is_group',
            onChange: onChange
        }
    ]

    return (
        <div className="page-content">
            <Create
                fields={fields}
                title={title}
                context={context}
                onSubmit={onSubmit}
            ></Create>
        </div>
    )
}

export default CreateBadge
