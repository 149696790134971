import React, { useEffect, useState, useContext } from "react"
import { useParams, useHistory } from "react-router-dom";
import { upload } from "../../services/mcp.service"
import AppContext from '../../store/AppContext'
import { createProduct } from '../../services/coupon.service'
import {
    Button,
    Card,
    CardBody,
    Col,
    Row,
    CardTitle,
    Spinner,
    Container
} from "reactstrap"
import Select from "react-select"

const CreateProduct = (props) => {
    const history = useHistory()
    const { group_id } = useParams()
    // console.log(group_id)
    const { notify } = useContext(AppContext)
    const [thumb, setThumb] = useState()
    const [thumbUploading, setThumbUploading] = useState(false)
    const [cover, setCover] = useState()
    const [coverUploading, setCoverUploading] = useState(false)

    const initialState = {
        name: '',
        name_en: '',
        description: '',
        post_description: '',
        thumbnail: '',
        cover_image: '',
        discount_text: '',
        cash_price: 0,
        presale_price: 0,
        point_price: 0,
        group: group_id,
        limit_per_user: 1,
        total_qty: 0,
        is_limited: false,
        is_active: false,
        is_show: false,
        index: 999,
        expire_after: {
            unit: 'days',
            qty: 7,
            type: 'calculated',
            expire_at: new Date()
        },
    }
    const [units, setUnits] = useState([
        {
            "label": "Цаг",
            "value": "hours"
        },
        {
            "label": "Өдөр",
            "value": "days"
        },
        {
            "label": "Сар",
            "value": "months"
        },
    ])

    const [state, setState] = useState(initialState)

    const onSubmit = async () => {
        try {
            console.log(state)
            let result = await createProduct(state)
            console.log(result)
            setState(initialState)

            notify('tr', 'success', `Бүтээгдэхүүн амжилттай үүслээ.`)
            history.push(`/coupon/${group_id}`)
        } catch (err) {
            notify('tr', 'danger', `Бүтээгдэхүүн үүсгэхэд алдаа гарлаа. ${err.message}`)
            console.log(err)
        }
    }


    const onThumbnailChange = async (event) => {
        try {
            setThumbUploading(true)
            if (event.target.files && event.target.files[0]) {
                let img = event.target.files[0];
                const data = new FormData();
                data.append('image', img)
                let result = await upload(data)
                if (result.status_code !== 0) {
                    throw new Error(result.status)
                }
                console.log(`uploaded path : ${result.data.path}`)

                setState({
                    ...state,
                    ['thumbnail']: result.data.path,
                })
                setThumb(result.data.path)
            }
        } catch (err) {
            event.target.value = null
            console.log(err)
        } finally {
            setThumbUploading(false)
        }

    };

    const onCoverImageChange = async (event) => {
        try {
            setCoverUploading(true)
            if (event.target.files && event.target.files[0]) {
                let img = event.target.files[0];
                const data = new FormData();
                data.append('image', img)
                let result = await upload(data)
                if (result.status_code !== 0) {
                    throw new Error(result.status)
                }
                console.log(`uploaded path : ${result.data.path}`)

                setState({
                    ...state,
                    ['cover_image']: result.data.path,
                })
                setCover(result.data.path)
            }
        } catch (err) {
            event.target.value = null
            console.log(err)
        } finally {
            setCoverUploading(false)
        }

    };


    const onChange = (evt) => {
        const value = evt.target.value
        setState({
            ...state,
            [evt.target.name]: value,
        })
    }

    const toggleChange = (evt) => {
        setState({
            ...state,
            [evt.target.name]: !state[evt.target.name],
        })

    }

    const unitChange = (evt) => {
        console.log('unit change called', evt.value)
        const value = evt.value
        setState({
            ...state,
            expire_after: {
                ...state.expire_after,
                unit: evt.value
            }
        })
    }

    const onExpireAfterChange = (evt) => {
        const value = evt.target.value
        setState({
            ...state,
            expire_after: {
                ...state.expire_after,
                [evt.target.name]: value,
            }

        })
    }
    const expireAfterFixed = (
        <Row className="mb-3">
            <label
                htmlFor="example-datetime-local-input"
                className="col-md-2 col-form-label"
            >
                Дуусах огноо
            </label>
            <div className="col-md-10">
                <input
                    className="form-control"
                    type="datetime-local"
                    value={state.expire_after && state.expire_after.expire_at}
                    name="expire_at"
                    onChange={onExpireAfterChange}
                    id="example-datetime-local-input"
                />
            </div>
        </Row>)

    const expireAfterCalculated = (
        <Row className="mb-3">
            <Col sm="6">
                <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                >
                    Нэгж
                </label>
                <div className="col-md-10">
                    <Select
                        onChange={unitChange}
                        options={units}
                        classNamePrefix="select2-selection"
                        value={
                            units.filter(unit =>
                                unit.value === state.expire_after.unit)
                        }
                    />
                </div>
            </Col>
            <Col sm="6">
                <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                >
                    Дүн
                </label>
                <div className="col-md-10">
                    <input
                        className="form-control"
                        type="number"
                        value={state && state.expire_after && state.expire_after.qty}
                        name="qty"
                        onChange={onExpireAfterChange}
                    />
                </div>

            </Col>
        </Row>
    )


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col >
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4">Бүтээгдэхүүн үүсгэх</CardTitle>

                                    <Row>
                                        <Col md="6" sm="12">
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Нэр
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        placeholder="Нэр"
                                                        type="text"
                                                        value={state.name}
                                                        name="name"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Нэр
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        placeholder="Name EN"
                                                        type="text"
                                                        value={state.name_en}
                                                        name="name_en"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Зураг
                                                </label>
                                                <div className="col-md-7">
                                                    <div className="mt-3">
                                                        <Row>
                                                            <Col sm="8">
                                                                <input className="form-control" type="file" id="formFile" onChange={onThumbnailChange} />
                                                            </Col>

                                                            <Col sm="2">
                                                                <img src={thumb} height="70px" width="auto" />
                                                            </Col>
                                                            <Col sm="2">

                                                                {thumbUploading && <Spinner className="ms-2" color="primary" />}
                                                            </Col>

                                                        </Row>


                                                    </div>
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Cover зураг
                                                </label>
                                                <div className="col-md-7">
                                                    <div className="mt-3">
                                                        <Row>
                                                            <Col sm="8">
                                                                <input className="form-control" type="file" id="formFile" onChange={onCoverImageChange} />
                                                            </Col>

                                                            <Col sm="2">
                                                                <img src={cover} height="70px" width="auto" />
                                                            </Col>
                                                            <Col sm="2">
                                                                {coverUploading && <Spinner className="ms-2" color="primary" />}
                                                            </Col>

                                                        </Row>


                                                    </div>
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Тайлбар
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Тайлбар"
                                                        value={state.description}
                                                        name="description"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Post description
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Тайлбар"
                                                        value={state.post_description}
                                                        name="post_description"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Discount Text
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Хямдралын текст"
                                                        value={state.discount_text}
                                                        name="discount_text"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Худалдан авах лимит
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={state.limit_per_user}
                                                        name="limit_per_user"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Нийт тоо хэмжээ
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={state.total_qty}
                                                        name="total_qty"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Button color="primary" type="submit" onClick={onSubmit}>
                                                Үүсгэх
                                            </Button>
                                        </Col>
                                        <Col md="6">
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Cash Price
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={state.cash_price}
                                                        name="cash_price"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Presale Price
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={state.presale_price}
                                                        name="presale_price"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Point Price
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={state.point_price}
                                                        name="point_price"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Index
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={state.index}
                                                        name="index"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>

                                            <Row className="mb-3">
                                                <div className="form-check form-check-primary mb-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_active"
                                                        id="isActiveCheck"
                                                        checked={state.is_active}
                                                        onChange={toggleChange}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="isActiveCheck"
                                                    >
                                                        Идэвхитэй эсэх
                                                    </label>
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <div className="form-check form-check-primary mb-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_show"
                                                        id="isShowCheck"
                                                        checked={state.is_show}
                                                        onChange={toggleChange}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="isShowCheck"
                                                    >
                                                        Харуулах эсэх
                                                    </label>
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <div className="form-check form-check-primary mb-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_limited"
                                                        id="isSingleCheck"
                                                        checked={state.is_limited}
                                                        onChange={toggleChange}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="isSingleCheck"
                                                    >
                                                        Хязгаартай эсэх
                                                    </label>
                                                </div>
                                            </Row>
                                            <Row className="mb-3">

                                                <h5 className="font-size-14 mb-4">Хугацааны төрөл</h5>
                                                <div className="form-check mb-3">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="type"
                                                        id="exampleRadios1"
                                                        value="calculated"
                                                        checked={state.expire_after.type === 'calculated'}
                                                        onChange={onExpireAfterChange}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="exampleRadios1"
                                                    >
                                                        Calculated
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="type"
                                                        id="exampleRadios2"
                                                        value="fixed"
                                                        checked={state.expire_after.type === 'fixed'}
                                                        onChange={onExpireAfterChange}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="exampleRadios2"
                                                    >
                                                        Fixed
                                                    </label>
                                                </div>

                                            </Row>

                                            {state.expire_after.type === 'calculated' ? expireAfterCalculated : expireAfterFixed}

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreateProduct
