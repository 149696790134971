import React, { useEffect, useState } from 'react'
import { getPromotions } from '../../../services/spin.service'

import { Card, CardBody, Col, Row, CardTitle, Table, Button } from 'reactstrap'

import { Link } from 'react-router-dom'
import { Loading, Pagination } from '../../Common'
import CreateSpinPromotion from './CreateSpin'

const SpinPromotions = props => {
    const [promotions, setPromotions] = useState([])
    const [loading, setLoading] = useState(false)

    const styleGreen = { color: 'green' }
    const styleRed = { color: 'red' }

    const fetchPromotions = async () => {
        try {
            setLoading(true)
            let data = await getPromotions()
            setPromotions(data)
        } catch (err) {
            setPromotions([])
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchPromotions()
    }, [])

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md="11">
                                    <CardTitle>
                                        <h3>
                                            <b>Хүрд</b>
                                        </h3>
                                    </CardTitle>
                                </Col>
                                <Col md="1">
                                    <Button
                                        color="success"
                                        onClick={() => {
                                            window.location.href =
                                                '/spin/create'
                                        }}
                                    >
                                        Үүсгэх
                                    </Button>
                                </Col>
                            </Row>
                            <Loading loading={loading} />
                            <div className="table-responsive">
                                <Table className="align-middle mb-0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Лого</th>
                                            <th>Нэр</th>
                                            <th>Тайлбар</th>
                                            <th>Promotion</th>
                                            <th>Merchant</th>
                                            <th>Эхэлсэн хугацаа</th>
                                            <th>Дуусах хугацаа</th>
                                            <th>Идэвхитэй эсэх</th>
                                            <th>Засах</th>
                                            <th>Устгах</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {promotions?.map((spin, id) => (
                                            <tr key={spin._id}>
                                                <td scope="row">
                                                    <b>{id + 1}</b>
                                                </td>
                                                <td>{spin.logo}</td>
                                                <td>{spin.name}</td>
                                                <td>{spin.description}</td>
                                                <td>{spin.promotion}</td>
                                                <td>{spin.merchant}</td>
                                                <td>{spin.start_at}</td>
                                                <td>{spin.end_at}</td>
                                                <td
                                                    style={
                                                        spin.is_active
                                                            ? styleGreen
                                                            : styleRed
                                                    }
                                                >
                                                    {spin.is_active
                                                        ? 'Тийм'
                                                        : 'Үгүй'}
                                                </td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        color="primary"
                                                        onClick={() => {
                                                            window.location.href = `/spin/${spin._id}`
                                                        }}
                                                    >
                                                        Засах
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        color="primary"
                                                    >
                                                        Устгах
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default SpinPromotions
