import React, { useState, useContext } from 'react'
import ReactDrawer from 'react-drawer'
import { Link } from 'react-router-dom'
import 'react-drawer/lib/react-drawer.css'

// Reactstrap

import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu'
// import RightSidebar from "../CommonForBoth/RightSidebar"
import Select from 'react-select'

//i18n
import { withTranslation } from 'react-i18next'
import AppContext from '../../store/AppContext'

const Header = props => {
    const [search, setsearch] = useState(false)

    const { campaigns, setChosenCampaignId } = useContext(AppContext)

    const campaignChange = evt => {
        console.log(
            `Campaign changed:Setting Chosen Campaign id : ${evt.value}`
        )

        setChosenCampaignId(evt.value)
    }

    function toggleFullscreen() {
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen()
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen()
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                )
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen()
            }
        }
    }

    function tToggle() {
        var body = document.body
        body.classList.toggle('vertical-collpsed')
        body.classList.toggle('sidebar-enable')
    }

    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <button
                            type="button"
                            onClick={() => {
                                tToggle()
                            }}
                            className="btn btn-sm px-3 font-size-16 header-item "
                            id="vertical-menu-btn"
                        >
                            <i className="fa fa-fw fa-bars" />
                        </button>

                        <form className="app-search d-none d-lg-block">
                            <div className="position-relative">
                                <div style={{ width: '300px' }}>
                                    <Select
                                        onChange={campaignChange}
                                        options={campaigns}
                                        classNamePrefix="form-control"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="d-flex">
                        <div className="dropdown d-inline-block d-lg-none ms-2">
                            <button
                                onClick={() => {
                                    setsearch(!search)
                                }}
                                type="button"
                                className="btn header-item noti-icon "
                                id="page-header-search-dropdown"
                            >
                                <i className="mdi mdi-magnify" />
                            </button>
                            <div
                                className={
                                    search
                                        ? 'dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show'
                                        : 'dropdown-menu dropdown-menu-lg dropdown-menu-end p-0'
                                }
                                aria-labelledby="page-header-search-dropdown"
                            >
                                <form className="p-3">
                                    <div className="form-group m-0">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search ..."
                                                aria-label="Recipient's username"
                                            />
                                            <div className="input-group-append">
                                                <button
                                                    className="btn btn-primary"
                                                    type="submit"
                                                >
                                                    <i className="mdi mdi-magnify" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="dropdown d-none d-lg-inline-block ms-1">
                            <button
                                type="button"
                                onClick={() => {
                                    toggleFullscreen()
                                }}
                                className="btn header-item noti-icon"
                                data-toggle="fullscreen"
                            >
                                <i className="bx bx-fullscreen" />
                            </button>
                        </div>

                        <ProfileMenu />
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}

export default withTranslation()(Header)
