import React, { useContext } from 'react'
import { Card, CardBody, Button, Col, Row, CardTitle, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Loading } from '../Common'
import ConfirmModal from '../Modal/ConfirmModal'
import { deleteGroup } from '../../services/coupon.service'
import AppContext from '../../store/AppContext'

const RewardList = ({ rewards, loading, refreshList, setLoading }) => {
    const styleGreen = { color: 'green' }
    const styleRed = { color: 'red' }

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={11}>
                                    <CardTitle className="h4">
                                        <h3>
                                            <b>Rewards</b>
                                        </h3>
                                    </CardTitle>
                                </Col>
                                <Col>
                                    <Button
                                        color="success"
                                        onClick={() => {
                                            window.location.href =
                                                '/reward/create'
                                        }}
                                    >
                                        Үүсгэх
                                    </Button>
                                </Col>
                            </Row>
                            <Loading loading={loading} />
                            <div className="table-responsive">
                                <Table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>Нэр</th>
                                            <th>Код</th>
                                            <th>Идэвхитэй эсэх</th>
                                            <th>Эхэлсэн огноо</th>
                                            <th>Дууссан огноо</th>
                                            <th>Төрөл</th>
                                            <th>Пойнт</th>
                                            <th>Купон</th>
                                            <th>Спин</th>
                                            <th>Засах</th>
                                            <th>Устгах</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rewards.map(reward => (
                                            <tr key={reward._id}>
                                                <td>{reward.name}</td>
                                                <td>{reward.short_code}</td>
                                                <td>
                                                    {reward.is_active
                                                        ? 'Тийм'
                                                        : 'Үгүй'}
                                                </td>
                                                <td>{reward.start_at}</td>
                                                <td>{reward.end_at}</td>
                                                <td>{reward.type}</td>
                                                <td>{reward.point}</td>
                                                <td>{reward.coupon}</td>
                                                <td>{reward.spin}</td>
                                                <td>
                                                    <Link
                                                        to={`/reward/${reward._id}`}
                                                    >
                                                        Засах
                                                    </Link>
                                                </td>
                                                <td style={styleRed}>Устгах</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default RewardList
