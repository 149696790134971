import React, { useEffect, useState, useContext } from 'react'
import { useParams, Link } from 'react-router-dom'
import { upload } from '../../services/mcp.service'
import { getGroup, updateGroup } from '../../services/coupon.service'
import { formatDatePicker } from '../../helpers/date_helper'
import ProductList from './ProductList'

import {
    Row,
    Container,
    Button,
    Card,
    CardBody,
    Col,
    CardTitle
} from 'reactstrap'

import Select from 'react-select'
import AppContext from '../../store/AppContext'

const GroupDetail = props => {
    const { group_id } = useParams()

    const { notify, merchants, segments } = useContext(AppContext)

    const [image, setImage] = useState()
    const [state, setState] = useState()
    // const [group, setGroup] = useState()
    const [groupLoading, setGroupLoading] = useState(true)

    useEffect(async () => {
        try {
            setGroupLoading(true)
            const result = await getGroup(group_id)
            result.data.start_at = formatDatePicker(result.data.start_at)
            result.data.end_at = formatDatePicker(result.data.end_at)
            setImage(result.data.thumbnail)
            setState(result.data)
        } catch (err) {
            console.log(err)
        } finally {
            setGroupLoading(false)
        }
    }, [])

    const onSubmit = async () => {
        try {
            let result = await updateGroup(group_id, state)
            notify('tr', 'success', `Группыг амжилттай шинэчиллээ.`)
        } catch (err) {
            notify('tr', 'danger', `Алдаа гарлаа. ${err.message}`)
            console.log(err)
        }
    }

    const onImageChange = async event => {
        try {
            if (event.target.files && event.target.files[0]) {
                let img = event.target.files[0]
                const data = new FormData()
                data.append('image', img)
                let result = await upload(data)
                if (result.status_code !== 0) {
                    throw new Error(result.status)
                }
                console.log(`uploaded path : ${result.data.path}`)

                setState({
                    ...state,
                    ['thumbnail']: result.data.path
                })
                setImage(result.data.path)
            }
        } catch (err) {
            event.target.value = null
            console.log(err)
        }
    }

    const onChange = evt => {
        const value = evt.target.value
        setState({
            ...state,
            [evt.target.name]: value
        })
    }

    const toggleChange = evt => {
        console.log(evt.target.name)
        setState({
            ...state,
            [evt.target.name]: !state[evt.target.name]
        })
    }

    const merchantChange = evt => {
        const value = evt.value
        setState({
            ...state,
            ['merchant']: value
        })
    }

    const segmentChange = evt => {
        const value = evt.value
        setState({
            ...state,
            ['segment']: value
        })
    }

    if (!state) {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <h3>Купон групп олдсонгүй</h3>
                    </Container>
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="page-title-box d-block align-items-center justify-content-between">
                        <div className="page-title-right">
                            <nav className="">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/coupon">Группууд</Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4">
                                        Групп дэлгэрэнгүй
                                    </CardTitle>
                                    <Row>
                                        <Col md="6" sm="12">
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Нэр
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        placeholder="Нэр"
                                                        type="text"
                                                        value={state.name}
                                                        name="name"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Зураг
                                                </label>
                                                <div className="col-md-6">
                                                    <div className="mt-3">
                                                        <Row>
                                                            <Col sm="8">
                                                                <input
                                                                    className="form-control"
                                                                    type="file"
                                                                    id="formFile"
                                                                    onChange={
                                                                        onImageChange
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col sm="4">
                                                                <img
                                                                    src={image}
                                                                    height="70px"
                                                                    width="70px"
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Тайлбар
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Тайлбар"
                                                        value={
                                                            state.description
                                                        }
                                                        name="description"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-datetime-local-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Эхлэх огноо
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="datetime-local"
                                                        value={state.start_at}
                                                        name="start_at"
                                                        onChange={onChange}
                                                        id="example-datetime-local-input"
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-datetime-local-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Дуусах огноо
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="datetime-local"
                                                        value={state.end_at}
                                                        name="end_at"
                                                        onChange={onChange}
                                                        id="example-datetime-local-input"
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Байгууллага
                                                </label>
                                                {/* <div className="col-md-10">
                                                    <Select
                                                        // value={selectedGroup}
                                                        onChange={
                                                            merchantChange
                                                        }
                                                        options={merchants}
                                                        // value={state.merchant}
                                                        value={merchants.filter(
                                                            merchant =>
                                                                merchant.value ===
                                                                state.merchant
                                                        )}
                                                        classNamePrefix="select2-selection"
                                                        // isLoading={merchantLoading}
                                                    />
                                                </div> */}
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Сегмент
                                                </label>
                                                <div className="col-md-10">
                                                    <Select
                                                        // value={selectedGroup}
                                                        onChange={segmentChange}
                                                        options={segments}
                                                        classNamePrefix="select2-selection"
                                                        value={segments.filter(
                                                            segment =>
                                                                segment.value ===
                                                                state.segment
                                                        )}
                                                        // isLoading={merchantLoading}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    UI Discount
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Хямдралын текст"
                                                        value={
                                                            state.ui_discount
                                                        }
                                                        name="ui_discount"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Button
                                                color="primary"
                                                type="submit"
                                                onClick={onSubmit}
                                            >
                                                Хадгалах
                                            </Button>
                                        </Col>
                                        <Col md="6">
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Cash Price
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={state.cash_price}
                                                        name="cash_price"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Presale Price
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={
                                                            state.presale_price
                                                        }
                                                        name="presale_price"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Point Price
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={
                                                            state.point_price
                                                        }
                                                        name="point_price"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>

                                            <Row className="mb-3">
                                                <div className="form-check form-check-primary mb-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_active"
                                                        id="isActiveCheck"
                                                        checked={
                                                            state.is_active
                                                        }
                                                        onChange={toggleChange}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="isActiveCheck"
                                                    >
                                                        Идэвхитэй эсэх
                                                    </label>
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <div className="form-check form-check-primary mb-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_show"
                                                        id="isShowCheck"
                                                        checked={state.is_show}
                                                        onChange={toggleChange}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="isShowCheck"
                                                    >
                                                        Харуулах эсэх
                                                    </label>
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <div className="form-check form-check-primary mb-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_single"
                                                        id="isSingleCheck"
                                                        checked={
                                                            state.is_single
                                                        }
                                                        onChange={toggleChange}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="isSingleCheck"
                                                    >
                                                        Дан групп эсэх
                                                    </label>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <ProductList groupId={group_id} />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default GroupDetail
