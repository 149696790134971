import React, { useEffect, useState, useContext } from 'react'
import { useParams, Link } from 'react-router-dom'
import IPPSservice from 'services/IPPSservice'
import ReactForm from 'ua-react-form'
const { Update } = ReactForm
import _ from 'lodash'

const ProfileDetail = () => {
    const { id } = useParams()
    const [context, setContext] = useState({})

    const getData = () => {
        try {
            let data = JSON.parse(localStorage.getItem('authUser'))
            console.log('Profile Detail: ', data)
            setContext(data)
        } catch (err) {
            console.log(err)
            setContext({})
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const title = 'User Profile'

    const fields = [
        { type: 'text', name: 'Нэр', fieldName: 'sub', readonly: true },
        {
            type: 'text',
            name: 'Role',
            fieldName: 'role',
            readonly: true
        },
        {
            type: 'text',
            name: 'Status',
            fieldName: 'status_code',
            readonly: true
        }
    ]

    return (
        <div className="page-content">
            <Update fields={fields} title={title} context={context}></Update>
        </div>
    )
}

export default ProfileDetail
