import React, { useEffect, useState, useContext } from 'react'
import { useParams, Link } from 'react-router-dom'
import IPPSservice from 'services/IPPSservice'
import { upload } from '../../services/mcp.service'
import ReactForm from 'ua-react-form'
import AppContext from '../../store/AppContext'
const { Update } = ReactForm
import _ from 'lodash'

const Detail = () => {
    const { id } = useParams()
    const [context, setContext] = useState({ body: {} })
    const { merchants } = useContext(AppContext)

    const onChange = ({ target }) => {
        let con = context
        _.set(con, target.name, target.value)
        setContext(con)
        console.log(con)
    }

    const onSubmit = async event => {
        try {
            event.preventDefault()
            const result = await IPPSservice.updateIPPS('mission', id, context)
            console.log(result)
        } catch (err) {
            console.log(err)
        }
    }

    const getData = async () => {
        try {
            let data = await IPPSservice.getOneIPPS('mission', id)
            console.log('Misson', data)
            setContext(data)
        } catch (err) {
            console.log(err)
            setContext({})
        }
    }

    useEffect(async () => {
        await getData()
    }, [])

    const title = 'Update Mission'
    const fields = [
        {
            type: 'text',
            onChange: onChange,
            name: 'Нэр',
            fieldName: 'name'
        },
        {
            type: 'text',
            onChange: onChange,
            name: 'Тайлбар',
            fieldName: 'description'
        },
        {
            type: 'text',
            onChange: onChange,
            name: 'Condition',
            fieldName: 'condition'
        },
        {
            type: 'text',
            onChange: onChange,
            name: 'Шагналын нэр',
            fieldName: 'prize_label'
        },
        {
            type: 'date',
            onChange: onChange,
            name: 'Эхлэх огноо',
            fieldName: 'start_date'
        },
        {
            type: 'date',
            onChange: onChange,
            name: 'Дуусах огноо',
            fieldName: 'end_date'
        },
        {
            type: 'select',
            onChange: onChange,
            name: 'Merchant',
            options: merchants,
            fieldName: 'merchant'
        },
        {
            type: 'number',
            onChange: onChange,
            name: 'Доод дүн',
            fieldName: 'min_target'
        },
        {
            type: 'number',
            onChange: onChange,
            name: 'Дээд дүн',
            fieldName: 'max_target'
        },
        {
            type: 'number',
            onChange: onChange,
            name: 'Percentage',
            fieldName: 'calculate_percentage'
        },
        {
            onChange: onChange,
            name: 'Харагдах эсэх',
            fieldName: 'is_show',
            type: 'boolean'
        },
        {
            onChange: onChange,
            name: 'Идэвхитэй эсэх',
            fieldName: 'is_active',
            type: 'boolean'
        },
        {
            onChange: onChange,
            name: 'Collective эсэх',
            fieldName: 'is_collective',
            type: 'boolean'
        },
        {
            type: 'text',
            onChange: onChange,
            name: 'Төрөл',
            fieldName: 'type'
        },
        {
            type: 'number',
            onChange: onChange,
            name: 'Хугацаа',
            fieldName: 'expire_after.quantity'
        },
        {
            type: 'select',
            onChange: onChange,
            name: 'Хугацаа-төрөл',
            fieldName: 'expire_after.unit'
        }
    ]

    return (
        <div className="page-content">
            <Update
                fields={fields}
                title={title}
                context={context}
                onSubmit={onSubmit}
            ></Update>
        </div>
    )
}

export default Detail
