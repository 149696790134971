import { post, get, put } from '../helpers/ipps_api_helper'

export async function getTransactions() {
    try {
        let result = await get('/transaction')
        return result.data
    } catch (err) {
        throw err
    }
}

export async function createTransaction(body) {
    try {
        let result = await post('/transaction', body)
        return result.data
    } catch (err) {
        throw err
    }
}

export async function updateTransaction(TransactionId, body) {
    try {
        let result = await put(`/transaction/${TransactionId}`, body)
        return result.data
    } catch (err) {
        throw err
    }
}

export default {
    getTransactions,
    createTransaction,
    updateTransaction
}
