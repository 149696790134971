import React, { useEffect, useState } from "react"
import {
    Container,
} from "reactstrap"
import SpinChoiceList from '../../components/Spin/Choice/SpinChoiceList'
import CreateSpinChoice from '../../components/Spin/Choice/CreateSpinChoice'

const SpinChoice = props => {

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <CreateSpinChoice />
                    <SpinChoiceList />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default SpinChoice
