import React, { useEffect, useState, useContext } from 'react'
import IPPSservice from 'services/IPPSservice'
import { upload } from '../../services/mcp.service'
import ReactForm from 'ua-react-form'
const { Create } = ReactForm
import AppContext from '../../store/AppContext'
import _ from 'lodash'

const CreateData = () => {
    const [context, setContext] = useState({})
    const { merchants } = useContext(AppContext)

    const onChange = ({ target }) => {
        let con = context
        _.set(con, target.name, target.value)
        setContext(con)
    }

    const onSubmit = async event => {
        try {
            event.preventDefault()
            const result = await IPPSservice.createIPPS('mission', context)
            console.log(result)
        } catch (err) {
            console.error(err)
        }
    }

    const title = 'Create Mission'

    const fields = [
        {
            type: 'text',
            onChange: onChange,
            name: 'Нэр',
            fieldName: 'name',
            isRequired: true
        },
        {
            type: 'text',
            onChange: onChange,
            name: 'Тайлбар',
            fieldName: 'description',
            isRequired: true
        },
        {
            type: 'text',
            onChange: onChange,
            name: 'Condition',
            fieldName: 'condition',
            isRequired: true
        },
        {
            type: 'text',
            onChange: onChange,
            name: 'Шагналын нэр',
            fieldName: 'prize_label',
            isRequired: true
        },
        {
            type: 'date',
            onChange: onChange,
            name: 'Эхлэх огноо',
            fieldName: 'start_date',
            isRequired: true
        },
        {
            type: 'date',
            onChange: onChange,
            name: 'Дуусах огноо',
            fieldName: 'end_date',
            isRequired: true
        },
        {
            type: 'select',
            onChange: onChange,
            name: 'Merchant',
            options: merchants,
            fieldName: 'merchant',
            isRequired: true
        },
        {
            type: 'number',
            onChange: onChange,
            name: 'Percentage',
            fieldName: 'calculate_percentage',
            isRequired: true
        },
        {
            type: 'number',
            onChange: onChange,
            name: 'Доод дүн',
            fieldName: 'min_target',
            isRequired: true
        },
        {
            type: 'number',
            onChange: onChange,
            name: 'Дээд дүн',
            fieldName: 'max_target',
            isRequired: true
        },
        {
            onChange: onChange,
            name: 'Харагдах эсэх',
            fieldName: 'is_show',
            isRequired: true,
            type: 'boolean'
        },
        {
            onChange: onChange,
            name: 'Идэвхитэй эсэх',
            fieldName: 'is_active',
            isRequired: true,
            type: 'boolean'
        },
        {
            onChange: onChange,
            name: 'Collective эсэх',
            fieldName: 'is_collective',
            isRequired: true,
            type: 'boolean'
        },
        {
            type: 'text',
            onChange: onChange,
            name: 'Төрөл',
            fieldName: 'type',
            isRequired: true
        },
        {
            type: 'number',
            onChange: onChange,
            name: 'Хугацаа',
            fieldName: 'expire_after.quantity',
            isRequired: true
        },
        {
            type: 'select',
            onChange: onChange,
            name: 'Хугацаа-төрөл',
            fieldName: 'expire_after.unit',
            isRequired: true
        }
    ]

    return (
        <div className="page-content">
            <Create
                fields={fields}
                title={title}
                context={context}
                onSubmit={onSubmit}
            ></Create>
        </div>
    )
}

export default CreateData
