import React, { useEffect, useState, useRef } from 'react'

import { Switch, BrowserRouter as Router, Redirect } from 'react-router-dom'

// Import Routes all
import { userRoutes, authRoutes } from './routes/allRoutes'

// Import all middleware
import Authmiddleware from './routes/middleware/Authmiddleware'
import { authService } from './services/auth.service'
import { getCampaigns } from './services/campaign.service'

// layouts Format
import VerticalLayout from './components/VerticalLayout/'
import NonAuthLayout from './components/NonAuthLayout'
import Login from './pages/Authentication/Login'
import AppContext from './store/AppContext'

// Import scss
import './assets/scss/theme.scss'
import { get } from './helpers/api_helper'

import NotificationAlert from 'react-notification-alert'
import segmentService from 'services/segment.service'
import transactionService from 'services/transaction.service'
import IPPSservice from 'services/IPPSservice'

const App = () => {
    const [authUser, setAuthUser] = useState(
        JSON.parse(localStorage.getItem('authUser'))
    )

    const notificationAlertRef = useRef(null)
    const notify = (place, type, text) => {
        var type
        var options = {}
        options = {
            place: place,
            message: (
                <div>
                    <div>{text}</div>
                </div>
            ),
            type: type,
            icon: 'nc-icon nc-bell-55',
            autoDismiss: 7
        }
        if (notificationAlertRef && notificationAlertRef.current) {
            notificationAlertRef.current.notificationAlert(options)
        }
    }

    const [campaigns, setCampaigns] = useState([])
    const [merchants, setMerchants] = useState([])

    const [segments, setSegments] = useState([
        {
            label: 'Бүгд',
            value: 'ALL'
        }
    ])

    const [chosenCampaignId, setChosenCampaignId] = useState()

    const fetchCampaigns = async () => {
        const data = await getCampaigns()
        setCampaigns(
            data &&
                data.data &&
                data.data.map(campaign => {
                    return {
                        label: campaign.name,
                        value: campaign._id
                    }
                })
        )
    }

    const fetchMerchants = async () => {
        try {
            if (!authUser) return
            let result = await get('/filter/merchant')
            setMerchants(
                result &&
                    result.data &&
                    result.data.map(merchant => {
                        return {
                            label: merchant.name_mn,
                            value: merchant._id
                        }
                    })
            )
            console.log(result)
            return result
        } catch (err) {
            throw err
        } finally {
        }
    }

    const fetchSegments = async () => {
        try {
            if (!authUser) return
            let result = await IPPSservice.getIPPS('segment')
            setSegments(
                result &&
                    result.map(segment => {
                        return {
                            label: segment.name,
                            value: segment._id
                        }
                    })
            )
            console.log(result)
            return result
        } catch (err) {
            throw err
        } finally {
        }
    }

    useEffect(async () => {
        try {
            if (authService.authUser) {
                authService.refreshToken()
                setInterval(function () {
                    authService.refreshToken()
                }, 4 * 60 * 1000)
            } else {
                authService.logout()
                setAuthUser(null)
            }
        } catch (z) {
            console.log(z)
        }
        let subscription = authService.subscriber.subscribe(updatedAuthUser => {
            setAuthUser(updatedAuthUser)
        })

        fetchCampaigns()
        fetchMerchants()
        fetchSegments()

        return function cleanup() {
            subscription.unsubscribe()
        }
    }, [])

    if (!authUser) {
        window.history.replaceState(null, 'Login', '/login')
        return <Login />
    }

    const contextValue = {
        campaigns: campaigns,
        fetchCampaigns,
        chosenCampaignId,
        setChosenCampaignId,
        merchants: merchants,
        setMerchants: setMerchants,
        authUser: authUser,
        segments: segments,
        notify
    }

    return (
        <React.Fragment>
            <AppContext.Provider value={contextValue}>
                <NotificationAlert ref={notificationAlertRef} />
                <Router>
                    <Switch>
                        {/* <div className="rna-container"> */}

                        {/* </div> */}
                        {authRoutes.map((route, idx) => (
                            <Authmiddleware
                                path={route.path}
                                layout={NonAuthLayout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={false}
                                exact
                            />
                        ))}

                        {userRoutes.map((route, idx) => (
                            <Authmiddleware
                                path={route.path}
                                layout={VerticalLayout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={false}
                                exact
                            />
                        ))}
                        <Redirect from="/" to="/404" />
                    </Switch>
                </Router>
            </AppContext.Provider>
        </React.Fragment>
    )
}

export default App
