import { post, get, put } from '../helpers/ipps_api_helper'

const createSegmentWithUsers = async data => {
    const result = await post('/segment/user', data)
    return result
}

export default {
    createSegmentWithUsers
}
