import { post, get, del } from '../helpers/ipps_api_helper'

export async function getIPPS(input) {
    try {
        let result = await get(`/${input}`)
        console.log(input, result)
        return result.data
    } catch (err) {
        throw err
    }
}

export async function getOneIPPS(input, id) {
    try {
        let result = await get(`/${input}/${id}`)
        console.log(input, id, result)
        return result.data
    } catch (err) {
        throw err
    }
}

export async function createIPPS(input, body) {
    try {
        let result = await post(`/${input}`, body)
        return result
    } catch (err) {
        throw err
    }
}

export async function updateIPPS(input, id, body) {
    try {
        let result = await post(`/${input}/${id}`, body)
        return result
    } catch (err) {
        throw err
    }
}

export async function deleteIPPS(input, id) {
    try {
        let result = await del(`/${input}/${id}`)
        return result
    } catch (err) {
        throw err
    }
}

export async function getNotificationPages() {
    try {
        let result = await get('/notification/pages')
        return result.data
    } catch (err) {
        console.log(err)
    }
}

export default {
    getIPPS,
    getOneIPPS,
    createIPPS,
    updateIPPS,
    deleteIPPS,
    getNotificationPages
}
