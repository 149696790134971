import React from 'react'
import IPPSservice from 'services/IPPSservice'
import ReactForm from 'ua-react-form'
const { List } = ReactForm

const DataList = ({ datas }) => {
    /*
        name: { type: String, required: true },
        description: { type: String },
        condition: { type: String },
        prize_label: { type: String, alias: 'prizelabel' },
        start_date: { type: Date, alias: 'startDate' },
        end_date: { type: Date, alias: 'endDate' },
        merchant: { type: mongoose.Types.ObjectId },
        // terminal_token: { type: String, alias: 'terminalToken' },
        min_target: { type: Number, alias: 'minTarget' },
        max_target: { type: Number, alias: 'maxTarget' },
        calculate_percentage: { type: Number, alias: 'calculatePercentage' },
        is_show: { type: Boolean, alias: 'isShow' },
        is_active: { type: Boolean, alias: 'isActive' },
        is_collective: { type: Boolean, alias: 'isCollective' },
        type: { type: String },
        product_description: { type: String, alias: 'productDescription' },
        // branches: [
        //     {
        //         name: { type: String },
        //         address: { type: String },
        //         phone: { type: String },
        //         logo: { type: String }
        //     }
        // ],
        // products: [String],
        // terminals: [String],
        expire_after: {
            unit: { type: String },
            quantity: { type: Number }
        },
        prize_product_id: {
            type: mongoose.Types.ObjectId,
            alias: 'prizeProductId'
        } */
    const fields = [
        { name: 'Нэр', fieldName: 'name' },
        { name: 'Тайлбар', fieldName: 'description' },
        { name: 'Condition', fieldName: 'condition' },
        { name: 'Шагналын нэр', fieldName: 'prize_label' },
        { name: 'Эхлэх огноо', fieldName: 'start_date' },
        { name: 'Дуусах огноо', fieldName: 'end_date' },
        { name: 'Merchant', fieldName: 'merchant.name' },
        { name: 'Доод дүн', fieldName: 'min_target' },
        { name: 'Дээд дүн', fieldName: 'max_target' },
        { name: 'Percentage', fieldName: 'calculate_percentage' },
        { name: 'Харагдах эсэх', fieldName: 'is_show', type: 'boolean' },
        { name: 'Идэвхитэй эсэх', fieldName: 'is_active', type: 'boolean' },
        {
            name: 'Collective эсэх',
            fieldName: 'is_collective',
            type: 'boolean'
        },
        { name: 'Төрөл', fieldName: 'type' },
        { name: 'Хугацаа', fieldName: 'expire_after.quantity' },
        { name: 'Хугацаа-төрөл', fieldName: 'expire_after.unit' }
    ]

    const buttons = [
        {
            color: 'success',
            onClick: () => {
                window.location.href += '/create'
            },
            label: 'Create'
        }
    ]

    const onDelete = async ({ id }) => {
        const result = await IPPSservice.deleteIPPS('badge', id)
        console.log('Delete Result: ', result)
    }
    console.log('mission uud', datas)
    return (
        <List
            fields={fields}
            title={'Missions'}
            width={12}
            datas={datas}
            buttons={buttons}
            editId="_id"
            Delete={{
                fieldName: 'name',
                onDelete: onDelete
            }}
        ></List>
    )
}

export default DataList
