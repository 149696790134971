import React, { useEffect, useState } from 'react'
import { get } from '../../helpers/api_helper'
import { Container, Table } from 'reactstrap'
import List from 'ua-react-form/dist/components/List'

const Dashboard = props => {
    const [merchants, setMerchants] = useState([])

    const fetchMerchants = async () => {
        try {
            let result = await get('/filter/merchant')
            setMerchants(result.data)
            console.log(result)
            return result
        } catch (err) {
            throw err
        }
    }
    useEffect(() => {
        fetchMerchants()
        console.log(merchants)
    }, [])

    const fields = [
        { name: 'ID', fieldName: '_id' },
        { name: 'Нэр', fieldName: 'name' },
        { name: 'Монгол нэр', fieldName: 'name_mn' }
    ]

    return (
        <React.Fragment>
            <div className="page-content">
                <List
                    fields={fields}
                    title={'Missions'}
                    width={12}
                    datas={merchants}
                ></List>
            </div>
        </React.Fragment>
    )
}

export default Dashboard
