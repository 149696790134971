import React from 'react'
import IPPSservice from 'services/IPPSservice'
import ReactForm from 'ua-react-form'
const { List } = ReactForm

const DataList = ({ datas }) => {
    const fields = [
        { name: 'Нэр', fieldName: 'name' },
        { name: 'Short Code', fieldName: 'short_code' },
        { name: 'Тайлбар', fieldName: 'description' }
    ]

    const onCreate = () => {}

    const buttons = [
        {
            label: 'Үүсгэх',
            onClick: () => {
                window.location.href += '/create'
            },
            color: 'success'
        }
    ]

    const onDelete = async ({ id }) => {
        const result = await IPPSservice.deleteIPPS('segment', id)
        console.log('Delete Result: ', result)
    }

    return (
        <List
            fields={fields}
            title={'Segments'}
            onCreate={onCreate}
            datas={datas}
            buttons={buttons}
            editId={'_id'}
            Delete={{
                fieldName: 'name',
                onDelete: onDelete
            }}
        ></List>
    )
}

export default DataList
