import React from 'react'
import { Redirect } from 'react-router-dom'

// Profile
import UserProfile from '../pages/Authentication/user-profile'

// Authentication related pages
import Login from '../pages/Authentication/Login'
import Logout from '../pages/Authentication/Logout'
import Register from '../pages/Authentication/Register'
import ForgetPwd from '../pages/Authentication/ForgetPassword'

// Dashboard
import Dashboard from '../pages/Dashboard/index'
import Spin from '../pages/Spin/index'
import SpinCreate from '../components/Spin/Promotion/CreateSpin'
import SpinDetail from '../components/Spin/Promotion/SpinDetail'
import SpinChoice from '../pages/Spin/choice'
import GroupDetail from '../components/Coupon/GroupDetail'
import CreateProduct from '../components/Coupon/CreateProduct'
import ProductDetail from '../components/Coupon/ProductDetail'
import { RewardDetail, CreateReward } from '../components/Reward'
import Coupon from '../pages/Coupon/index'
import CreateCoupon from '../components/Coupon/CreateGroup'
// import Coupon from "../pages/Coupon/index"
import NotFound from '../pages/Util/pages-404'
import Campaign from '../pages/Campaign/index'
import CampaignCreate from '../components/Campaign/Create'
import CampaignDetail from '../components/Campaign/Detail'
import Notification from '../pages/Notification/index'
import NotificationCreate from '../components/Notification/createNotification'
import NotificationDetail from '../components/Notification/notificationDetail'
import Reward from '../pages/Reward/index'
import Segment from '../pages/Segment/index'
import SegmentCreate from '../components/Segment/SegmentCreate'
import SegmentDetail from '../components/Segment/segmentDetail'
import Badge from '../pages/Badge'
import BadgeCreate from '../components/Badge/Create'
import BadgeDetail from '../components/Badge/Detail'
import Mission from '../pages/Mission'
import MissionCreate from '../components/Mission/Create'
import MissionDetail from '../components/Mission/Detail'

const userRoutes = [
    { path: '/dashboard', component: Dashboard },
    { path: '/spin', component: Spin },
    { path: '/spin/create', component: SpinCreate },
    { path: '/spin/choice', component: SpinChoice },
    { path: '/spin/:promotion_id', component: SpinDetail },
    { path: '/coupon', component: Coupon },
    { path: '/coupon/create', component: CreateCoupon },
    { path: '/coupon', component: Coupon },
    { path: '/campaign', component: Campaign },
    { path: '/campaign/create', component: CampaignCreate },
    { path: '/campaign/:id', component: CampaignDetail },
    { path: '/notification', component: Notification },
    { path: '/notification/create', component: NotificationCreate },
    { path: '/notification/:id', component: NotificationDetail },
    { path: '/segment', component: Segment },
    { path: '/segment/create', component: SegmentCreate },
    { path: '/segment/:id', component: SegmentDetail },
    { path: '/reward/create', component: CreateReward },
    { path: '/reward/:reward_id', component: RewardDetail },
    { path: '/reward', component: Reward },
    { path: '/badge', component: Badge },
    { path: '/badge/create', component: BadgeCreate },
    { path: '/badge/:id', component: BadgeDetail },
    { path: '/mission', component: Mission },
    { path: '/mission/create', component: MissionCreate },
    { path: '/mission/:id', component: MissionDetail },

    { path: '/coupon/:group_id', component: GroupDetail }, // Product Group detail
    { path: '/coupon/:group_id/add_product', component: CreateProduct }, // Product Group detail
    { path: '/coupon/product/:product_id', component: ProductDetail }, //Product detail

    // //profile
    { path: '/profile', component: UserProfile },
    { path: '/404', component: NotFound },
    { path: '/', exact: true, component: () => <Redirect to="/campaign" /> }
]

const authRoutes = [
    { path: '/logout', component: Logout },
    // { path: "/login", component: Login },
    { path: '/forgot-password', component: ForgetPwd },
    { path: '/register', component: Register }
]

export { userRoutes, authRoutes }
