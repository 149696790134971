import React, { useEffect, useState } from 'react'
import { getChoices } from '../../../services/spin.service'
import { Card, CardBody, Col, Row, CardTitle, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Loading, Pagination } from '../../Common'

const SpinChoices = props => {
    const [choices, setChoices] = useState([])
    const [loading, setLoading] = useState(false)
    const styleGreen = { color: 'green' }
    const styleRed = { color: 'red' }

    const fetchChoices = async () => {
        try {
            setLoading(true)
            let data = await getChoices()
            console.log(data)
            setChoices(data)
        } catch (err) {
            console.log(err)
            setChoices([])
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchChoices()
    }, [])

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle className="h4">
                                Хүрдний сонголтууд
                            </CardTitle>
                            <Loading loading={loading} />
                            <div className="table-responsive">
                                <Table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>Нэр</th>
                                            <th>үүссэн огноо</th>
                                            <th>Үлдэгдэл</th>
                                            <th>Нийт тоо ширхэг</th>
                                            <th>Магадлал</th>
                                            <th>Статус</th>
                                            <th>Хязгаартай эсэх</th>
                                            <th>Шагналын төрөл</th>
                                            <th>Шагналын lvl</th>
                                            <th>Шагналын оноо</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {choices &&
                                            choices.map(choice => (
                                                <tr key={choice._id}>
                                                    <td>
                                                        <Link
                                                            to={`/spin/choice/${choice._id}`}
                                                        >
                                                            {choice.name}
                                                        </Link>
                                                    </td>
                                                    <td>{choice.created_at}</td>
                                                    <td>{choice.stock}</td>
                                                    <td>{choice.total_qty}</td>
                                                    <td>
                                                        {choice.probability}
                                                    </td>
                                                    <td
                                                        style={
                                                            choice.is_active
                                                                ? styleGreen
                                                                : styleRed
                                                        }
                                                    >
                                                        {choice.is_active
                                                            ? 'Тийм'
                                                            : 'Үгүй'}
                                                    </td>
                                                    <td
                                                        style={
                                                            choice.is_limited
                                                                ? styleGreen
                                                                : styleRed
                                                        }
                                                    >
                                                        {choice.is_limited
                                                            ? 'Тийм'
                                                            : 'Үгүй'}
                                                    </td>
                                                    <td>{choice.prize_type}</td>
                                                    <td>
                                                        {choice.prize_level}
                                                    </td>
                                                    <td>{choice.point}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default SpinChoices
