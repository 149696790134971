import { BehaviorSubject } from 'rxjs'
import axios from 'axios'
const baseUrl = 'https://auth.upoint.mn'

const subscriber = new BehaviorSubject(
    JSON.parse(localStorage.getItem('authUser'))
)

const updateAuthUser = function (authUser) {
    subscriber.next(authUser)
}

const login = async function (username, password) {
    try {
        let response = await axios({
            url: baseUrl + '/internal/login',
            method: 'POST',
            data: {
                username,
                password,
                client_id: 'CqSs1QQbRCUuE4'
            }
        })
        if (response.data.status_code === 0) {
            localStorage.setItem('authUser', JSON.stringify(response.data))
            updateAuthUser(response.data)
        } else {
            throw Error(response.data.message)
        }
    } catch (err) {
        throw err
    }
}

const logout = async function () {
    try {
        localStorage.removeItem('authUser')
        updateAuthUser(null)
        return
    } catch (err) {
        console.log(err)
    }
}

const refreshToken = async () => {
    try {
        let response = await axios({
            url: baseUrl + `/internal/refresh`,
            data: {
                refresh_token: subscriber.value.refresh_token,
                client_id: 'CqSs1QQbRCUuE4'
            },
            method: 'POST',
            headers: {
                Authorization: `Bearer ${subscriber.value.access_token}`
            }
        })
        console.log(response)

        if (response.data.error === 'TokenExpired') {
            logout()
        }

        if (response.data.status_code === 0) {
            localStorage.setItem('authUser', JSON.stringify(response.data))
            updateAuthUser(response.data)
        } else {
            throw Error(response.data.error_description)
        }
        console.log(response)
    } catch (err) {
        console.log('err', err)
        throw err
    }
}
export const authService = {
    login,
    refreshToken,
    logout,
    updateAuthUser,
    subscriber,
    get authUser() {
        return subscriber.value
    }
}
