import { post, get, put } from '../helpers/ipps_api_helper'

export async function getRewards() {
    try {
        let result = await get('/reward')
        return result.data
    } catch (err) {
        throw err
    }
}

export async function getReward(id) {
    try {
        let result = await get(`/reward/${id}`)
        return result.data
    } catch (err) {
        throw err
    }
}

export async function createReward(body) {
    try {
        let result = await post('/reward', body)
        return result.data
    } catch (err) {
        throw err
    }
}

export async function updateReward(RewardId, body) {
    try {
        let result = await put(`/reward/${RewardId}`, body)
        return result.data
    } catch (err) {
        throw err
    }
}

export async function rewardGrant(body) {
    try {
        let result = await post(`/reward/grant`, body)
        return result.data
    } catch (err) {
        throw err
    }
}

export default {
    getRewards,
    getReward,
    createReward,
    updateReward,
    rewardGrant
}
