import React, { useEffect, useState } from 'react'
import { get } from '../../../helpers/api_helper'
import { Button, Card, CardBody, Col, Row, CardTitle } from 'reactstrap'
import Select from 'react-select'

const CreateSpin = props => {
    const [merchants, setMerchants] = useState([])
    const [merchantLoading, setMerchantLoading] = useState(false)
    const [customchkPrimary, setcustomchkPrimary] = useState(true)

    const fetchMerchants = async () => {
        try {
            setMerchantLoading(true)
            let result = await get('/filter/merchant')
            setMerchants(
                result.data.map(merchant => {
                    return {
                        label: merchant.name_mn,
                        value: merchant.name
                    }
                })
            )
            console.log(result)
            return result
        } catch (err) {
            throw err
        } finally {
            setMerchantLoading(false)
        }
    }
    useEffect(() => {
        fetchMerchants()
        console.log(merchants)
    }, [])
    const [selectedGroup, setselectedGroup] = useState(null)

    const [selectedPrizeType, setselectedPrizeType] = useState(null)

    const prizeTypes = [
        {
            label: 'Оноо',
            value: 'point'
        },
        {
            label: 'Купон',
            value: 'product'
        }
    ]

    function handleSelectGroup(selectedGroup) {
        console.log(selectedGroup)
        setselectedGroup(selectedGroup)
    }

    function handleSelectPrizeType(selectedGroup) {
        console.log(selectedGroup)
        setselectedPrizeType(selectedGroup)
    }

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle className="h4">Сонголт үүсгэх</CardTitle>

                            <Row>
                                <Col md="6" sm="12">
                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            Нэр
                                        </label>
                                        <div className="col-md-10">
                                            <input
                                                className="form-control"
                                                type="text"
                                                defaultValue="Choice 1"
                                            />
                                        </div>
                                    </Row>
                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            Урамшуулал
                                        </label>
                                        <div className="col-md-10">
                                            <Select
                                                value={selectedGroup}
                                                onChange={e => {
                                                    handleSelectGroup(e)
                                                }}
                                                options={merchants}
                                                classNamePrefix="select2-selection"
                                                isLoading={merchantLoading}
                                            />
                                        </div>
                                    </Row>
                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-number-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            Магадлал
                                        </label>
                                        <div className="col-md-10">
                                            <input
                                                className="form-control"
                                                type="number"
                                                defaultValue="1"
                                                id="example-number-input"
                                            />
                                        </div>
                                    </Row>
                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            Шагнал төрөл
                                        </label>
                                        <div className="col-md-10">
                                            <Select
                                                value={selectedPrizeType}
                                                onChange={e => {
                                                    handleSelectPrizeType(e)
                                                }}
                                                options={prizeTypes}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Row>

                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-number-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            Тоо ширхэг
                                        </label>
                                        <div className="col-md-10">
                                            <input
                                                className="form-control"
                                                type="number"
                                                defaultValue="42"
                                                id="example-number-input"
                                            />
                                        </div>
                                    </Row>
                                </Col>
                                <Col md="6" sm="12">
                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            Нэр
                                        </label>
                                        <div className="col-md-10">
                                            <input
                                                className="form-control"
                                                type="text"
                                                defaultValue="Choice 1"
                                            />
                                        </div>
                                    </Row>
                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            Урамшуулал
                                        </label>
                                        <div className="col-md-10">
                                            <Select
                                                value={selectedGroup}
                                                onChange={e => {
                                                    handleSelectGroup(e)
                                                }}
                                                options={merchants}
                                                classNamePrefix="select2-selection"
                                                isLoading={merchantLoading}
                                            />
                                        </div>
                                    </Row>
                                    <Row className="mb-3">
                                        <div className="form-check form-check-primary mb-3">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="customCheckcolor1"
                                                checked={customchkPrimary}
                                                onChange={() => {
                                                    setcustomchkPrimary(
                                                        !customchkPrimary
                                                    )
                                                }}
                                            />

                                            <label
                                                className="form-check-label"
                                                htmlFor="customCheckcolor1"
                                            >
                                                Идэвхитэй эсэх
                                            </label>
                                        </div>
                                    </Row>
                                    <Button color="primary" type="submit">
                                        Үүсгэх
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default CreateSpin
