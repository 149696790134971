import React from 'react'
import IPPSservice from 'services/IPPSservice'
import ReactForm from 'ua-react-form'
const { List } = ReactForm

const DataList = ({ datas }) => {
    const fields = [
        { name: 'Нэр', fieldName: 'name' },
        { name: 'Монгол нэр', fieldName: 'name_mn' },
        { name: 'Тайлбар', fieldName: 'description' },
        { name: 'Идэвхитэй эсэх', fieldName: 'is_active', type: 'boolean' },
        { name: 'Эхлэх хугацаа', fieldName: 'start_at' },
        { name: 'Дуусах хугацаа', fieldName: 'end_at' }
    ]

    const buttons = [
        {
            color: 'success',
            onClick: () => {
                window.location.href += '/create'
            },
            label: 'Create'
        }
    ]

    const onDelete = async ({ id }) => {
        const result = await IPPSservice.deleteIPPS('campaign', id)
        console.log('Delete Result: ', result)
    }

    console.log('campaign uud', datas)

    return (
        <List
            fields={fields}
            title={'Campaign'}
            width={12}
            datas={datas}
            buttons={buttons}
            editId="_id"
            Delete={{
                fieldName: 'name',
                onDelete: onDelete
            }}
        ></List>
    )
}

export default DataList
