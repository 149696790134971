import MetaTags from 'react-meta-tags'
import React from 'react'
import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap'

import { withRouter, Link } from 'react-router-dom'

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation'
// import images
import profile from 'assets/images/profile-img.png'
import logo from 'assets/images/logo.svg'
import { authService } from '../../services/auth.service'

const Login = props => {
    // handleValidSubmit
    const handleValidSubmit = async (event, values) => {
        // props.loginUser(values, props.history)
        try {
            let response = await authService.login(
                values.username,
                values.password
            )
            window.location.href = '/'
            console.log(response)
        } catch (err) {
            console.log(err)
        }

        // console.log(values.username)
        // console.log(event, values)
    }

    return (
        <React.Fragment>
            <MetaTags>
                <title>Login | CMS - Campaign Management System</title>
            </MetaTags>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <Row>
                                        <Col xs={7}>
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">
                                                    Welcome !
                                                </h5>
                                                <p>CMS - UPoint</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end col">
                                            <img
                                                src={
                                                    'https://upoint.mn/app_assets/static/upoint.png'
                                                }
                                                alt="UPoint"
                                                className="img-fluid"
                                                style={{
                                                    margin: '20px',
                                                    width: '80%',
                                                    boxShadow:
                                                        '1px 3px 0px 1px #9E9E9E',
                                                    borderRadius: '10%'
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title rounded-circle bg-light">
                                                <img
                                                    src={
                                                        'https://upoint.mn/app_assets/static/upoint.png'
                                                    }
                                                    alt=""
                                                    className="rounded-circle"
                                                    height="34"
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="p-2">
                                        <AvForm
                                            className="form-horizontal"
                                            onValidSubmit={(e, v) => {
                                                handleValidSubmit(e, v)
                                            }}
                                        >
                                            {props.error ? (
                                                <Alert color="danger">
                                                    {props.error.message}
                                                </Alert>
                                            ) : null}

                                            <div className="mb-3">
                                                <AvField
                                                    name="username"
                                                    label="Username"
                                                    value=""
                                                    className="form-control"
                                                    placeholder="Enter username"
                                                    required
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <AvField
                                                    name="password"
                                                    label="Password"
                                                    value=""
                                                    type="password"
                                                    required
                                                    placeholder="Enter Password"
                                                />
                                            </div>

                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customControlInline"
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customControlInline"
                                                >
                                                    Remember me
                                                </label>
                                            </div>

                                            <div className="mt-3 d-grid">
                                                <button
                                                    className="btn btn-primary btn-block"
                                                    type="submit"
                                                >
                                                    Log In
                                                </button>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                            <Row style={{ textAlign: 'center' }}>
                                <div>© 2021 CMS. Crafted by Badral</div>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(Login)
