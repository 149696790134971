import React, { useEffect, useState } from "react"
import {
    Container,
} from "reactstrap"
import SpinPromotions from '../../components/Spin/Promotion/SpinList'

const Spin = props => {

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <SpinPromotions />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Spin
