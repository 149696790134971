import axios from "axios"
const API_URL = "http://10.10.10.161:3040"

import { authService } from '../services/auth.service'


const axiosApi = axios.create({
    baseURL: API_URL,
})

axiosApi.interceptors.response.use(
    response => {
        // console.log(response)
        if (response && response.data && response.data.code === 401) {
            authService.logout()
        }
        return response
    },
    error => Promise.reject(error)
)

axiosApi.interceptors.request.use(
    async config => {
        const authUser = authService.authUser
        if (authUser) {
            console.log('api_helper', authUser)
            config.headers = {
                'Authorization': `Bearer ${authUser.access_token}`,
            }
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

export async function get(url, config = {}) {
    return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
    const response = await axiosApi.post(url, { ...data }, { ...config })
    console.log(response)
    return response.data
}

export async function postForm(url, data) {
    const response = await axiosApi.post(url, data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
    console.log(response)
    return response.data
}

export async function put(url, data, config = {}) {
    return axiosApi
        .put(url, { ...data }, { ...config })
        .then(response => response.data)
}

export async function del(url, config = {}) {
    return await axiosApi
        .delete(url, { ...config })
        .then(response => response.data)
}
