import React, { useEffect, useState } from 'react'
import { Container, Table } from 'reactstrap'
import IPPSservice from 'services/IPPSservice'
import rewardService from 'services/reward.service'
import segmentService from 'services/segment.service'
import { List, Create } from '../../components/Reward'

const Reward = props => {
    const [rewards, setRewards] = useState([])
    const [segments, setSegments] = useState([])
    const [loading, setLoading] = useState(false)

    const refreshList = async () => {
        console.log('Refreshing...')
        try {
            setLoading(true)
            let data = await IPPSservice.getIPPS('reward')
            const segs = await IPPSservice.getIPPS('segment')
            setSegments(segs)
            setRewards(data)
        } catch (err) {
            console.log(err)
            setRewards([])
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        refreshList()
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <List
                        rewards={rewards}
                        loading={loading}
                        refreshList={refreshList}
                        setLoading={setLoading}
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Reward
