import { post, get, del } from '../helpers/ipps_api_helper'

// SpinChoice

export async function getChoices() {
    try {
        let result = await get('/spin/choice')
        return result.data
    } catch (err) {
        throw err
    }
}

export async function createChoice(body) {
    try {
        let result = await post('/spin/choice', body)
        return result.data
    } catch (err) {
        throw err
    }
}

export async function updateChoice(choiceId, body) {
    try {
        let result = await post(`/spin/choice/${choiceId}`, body)
        return result.data
    } catch (err) {
        throw err
    }
}

export async function deleteChoice(choiceId) {
    try {
        let result = await del(`/spin/choice/${choiceId}`)
        return result.data
    } catch (err) {
        throw err
    }
}

// SpinPromotion

export async function getPromotions() {
    try {
        let result = await get('/spin/promotion')
        return result.data
    } catch (err) {
        throw err
    }
}

export async function getPromotion(promotion_id) {
    try {
        let result = await get(`/spin/promotion/${promotion_id}`)
        return result.data
    } catch (err) {
        throw err
    }
}

export async function createPromotion(body) {
    try {
        let result = await post('/spin/promotion', body)
        return result.data
    } catch (err) {
        throw err
    }
}

export async function updatePromotion(promotionId, body) {
    try {
        let result = await post(`/spin/promotion/${promotionId}`, body)
        return result.data
    } catch (err) {
        throw err
    }
}

export async function deletePromotion(promotionId) {
    try {
        let result = await del(`/spin/promotion/${promotionId}`)
        return result.data
    } catch (err) {
        throw err
    }
}

export default {
    getChoices,
    createChoice,
    updateChoice,
    deleteChoice,
    getPromotions,
    getPromotion,
    createPromotion,
    updatePromotion,
    deletePromotion
}
