import React, { useEffect, useState, useContext } from 'react'
import { useParams, Link } from 'react-router-dom'
import { upload } from '../../services/mcp.service'
import { getReward, updateReward } from '../../services/reward.service'
import { formatDatePicker } from '../../helpers/date_helper'

import {
    Row,
    Container,
    Button,
    Card,
    CardBody,
    Col,
    CardTitle
} from 'reactstrap'

import Select from 'react-select'
import AppContext from '../../store/AppContext'
import { entries } from 'lodash'

const RewardDetail = () => {
    const { reward_id } = useParams()

    const { notify, merchants, segments } = useContext(AppContext)

    const [state, setState] = useState()
    // const [group, setGroup] = useState()
    const [rewardLoading, setRewardLoading] = useState(true)

    useEffect(async () => {
        try {
            setRewardLoading(true)
            const result = (await getReward(reward_id)).result
            console.log('asdasd' + result)
            result.start_at = formatDatePicker(result.start_at)
            result.end_at = formatDatePicker(result.end_at)
            setState(result)
        } catch (err) {
            console.log(err)
        } finally {
            setRewardLoading(false)
        }
    }, [])

    const onSubmit = async () => {
        try {
            let result = await updateReward(reward_id, state)
            notify('tr', 'success', `Reward амжилттай шинэчиллээ.`)
        } catch (err) {
            notify('tr', 'danger', `Алдаа гарлаа. ${err.message}`)
            console.log(err)
        }
    }

    const onChange = evt => {
        const value = evt.target.value

        if (
            evt.target.name === 'point' ||
            evt.target.name === 'spin' ||
            evt.target.name === 'coupon'
        ) {
            setState({
                ...state,
                point: 0,
                spin: '',
                coupon: ''
            })
        }

        setState({
            ...state,
            [evt.target.name]: value
        })
    }
    const onChange3 = evt => {
        const value = evt.target.value

        setState({
            ...state,
            point: 0,
            spin: '',
            coupon: '',
            type: evt.target.name,
            [evt.target.name]: value
        })
    }
    const toggleChange = evt => {
        console.log(evt.target.name)
        setState({
            ...state,
            [evt.target.name]: !state[evt.target.name]
        })
    }

    const onChangeType = evt => {
        const value = evt.target.value
        setState({
            ...state,
            [evt.target.name]: value
        })
    }

    if (!state) {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <h3>Reward олдсонгүй</h3>
                    </Container>
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="page-title-box d-block align-items-center justify-content-between">
                        <div className="page-title-right">
                            <nav className="">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/reward">
                                            <h2>Rewards</h2>
                                        </Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4">
                                        Reward дэлгэрэнгүй
                                    </CardTitle>
                                    <Row>
                                        <Col md="6" sm="12">
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Нэр
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        placeholder="Нэр"
                                                        type="text"
                                                        value={state.name}
                                                        name="name"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>

                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Код
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Код"
                                                        value={state.short_code}
                                                        name="code"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>

                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-datetime-local-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Эхлэх огноо
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="datetime-local"
                                                        value={state.start_at}
                                                        name="start_at"
                                                        onChange={onChange}
                                                        id="example-datetime-local-input"
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-datetime-local-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Дуусах огноо
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="datetime-local"
                                                        value={state.end_at}
                                                        name="end_at"
                                                        onChange={onChange}
                                                        id="example-datetime-local-input"
                                                    />
                                                </div>
                                            </Row>

                                            <Button
                                                color="primary"
                                                type="submit"
                                                onClick={onSubmit}
                                            >
                                                Хадгалах
                                            </Button>
                                        </Col>
                                        <Col md="6">
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Гүйлгээ дугаар
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Гүйлгээний дугаар"
                                                        value={
                                                            state.transaction_config
                                                        }
                                                        name="transaction_config"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Төрөл
                                                </label>
                                                <select
                                                    name="type"
                                                    value={state.type}
                                                    onChange={onChange}
                                                >
                                                    <option
                                                        selected
                                                        value="nothing"
                                                    >
                                                        Төрлөө сонгоно уу!
                                                    </option>
                                                    <option value="point">
                                                        Point
                                                    </option>
                                                    <option value="coupon">
                                                        Coupon
                                                    </option>
                                                    <option value="spin">
                                                        Spin
                                                    </option>
                                                </select>
                                            </Row>
                                            {state.type === 'point' ? (
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label"
                                                    >
                                                        Point
                                                    </label>
                                                    <div className="col-md-10">
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            value={state.point}
                                                            name="point"
                                                            onChange={onChange3}
                                                        />
                                                    </div>
                                                </Row>
                                            ) : (
                                                <></>
                                            )}

                                            {state.type === 'coupon' ? (
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label"
                                                    >
                                                        Coupon
                                                    </label>
                                                    <div className="col-md-10">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={state.coupon}
                                                            name="coupon"
                                                            onChange={onChange3}
                                                        />
                                                    </div>
                                                </Row>
                                            ) : (
                                                <></>
                                            )}

                                            {state.type === 'spin' ? (
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label"
                                                    >
                                                        Spin
                                                    </label>
                                                    <div className="col-md-10">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={state.spin}
                                                            name="spin"
                                                            onChange={onChange3}
                                                        />
                                                    </div>
                                                </Row>
                                            ) : (
                                                <></>
                                            )}

                                            <Row className="mb-3">
                                                <Row className="mb-3"></Row>
                                                <div className="form-check form-check-primary mb-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_active"
                                                        id="isActiveCheck"
                                                        checked={
                                                            state.is_active
                                                        }
                                                        onChange={toggleChange}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="isActiveCheck"
                                                    >
                                                        Идэвхитэй эсэх
                                                    </label>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default RewardDetail
