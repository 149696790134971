import Moment from 'moment'
const formatPretty = (dt) => {
    return Moment.utc(dt).format('YYYY-MM-DD HH:mm:ss')
}
const formatBeauty = (dt) => {
    return Moment(dt).format('YYYY-MM-DD HH:mm:ss')
}

const formatDatePicker = (dt) => {
    return Moment(dt).format('YYYY-MM-DDTHH:mm:ss')
}

const getCurrentDate = () => {
    return Moment().format('YYYY-MM-DD')
}

const getLastWeekDate = () => {
    return Moment().add(-7, 'days').format('YYYY-MM-DD')
}

const getStartOfDate = (date) => {
    return Moment(date, 'YYYY-MM-DD').startOf('day').format('YYYY-MM-DDTHH:mm')
}

const getEndOfDate = (date) => {
    return Moment(date, 'YYYY-MM-DD').endOf('day').format('YYYY-MM-DDTHH:mm')
}

export { formatPretty, formatBeauty, getCurrentDate, getLastWeekDate, getStartOfDate, getEndOfDate, formatDatePicker }
