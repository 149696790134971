import React, { useEffect, useState, useContext } from 'react'
import { useParams, Link } from 'react-router-dom'
import IPPSservice from 'services/IPPSservice'
import { upload } from '../../services/mcp.service'
import ReactForm from 'ua-react-form'
const { Update } = ReactForm
import _ from 'lodash'

const NotificationDetail = () => {
    const { id } = useParams()
    const [context, setContext] = useState({ body: {} })
    const [segments, setSegments] = useState([])
    const [pages, setPages] = useState([])

    const onChange = ({ target }) => {
        let con = context
        _.set(con, target.name, target.value)
        setContext(con)
        console.log(con)
    }

    const onChangeImage = async ({ target }) => {
        try {
            if (target.files && target.files[0]) {
                let img = target.files[0]
                const data = new FormData()
                data.append('image', img)
                let result = await upload(data)

                if (result.status_code !== 0) {
                    throw new Error(result.status)
                }

                let con = context
                _.set(con, target.name, result.data.path)
                setContext(con)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const onSubmit = async event => {
        try {
            event.preventDefault()
            const result = await IPPSservice.updateIPPS(
                'notification',
                id,
                context
            )
            console.log(result)
        } catch (err) {
            console.log(err)
        }
    }

    const getSegments = async () => {
        try {
            let segment = await IPPSservice.getIPPS('segment')
            segment = segment.map(seg => ({
                label: seg.name,
                value: seg.short_code
            }))
            setSegments([
                { value: '-1', label: 'Сегмэнт сонгоно уу!' },
                ...segment
            ])
        } catch (err) {
            console.log(err)
            setSegments([])
        }
    }

    const getPages = async () => {
        try {
            let pages = await IPPSservice.getNotificationPages()
            pages = pages.map(page => ({
                value: page._id,
                label: page.name
            }))
            setPages([{ value: '-1', label: 'Page Сонгоно уу!' }, ...pages])
        } catch (err) {
            console.log(err)
            setPages([])
        }
    }
    const getNotification = async () => {
        try {
            let notification = await IPPSservice.getOneIPPS('notification', id)
            console.log('Notification', notification)
            setContext(notification)
        } catch (err) {
            console.log(err)
            setContext({})
        }
    }

    useEffect(async () => {
        await getSegments()
        await getPages()
        await getNotification()
    }, [])

    const title = 'Update Notification'

    const fields = [
        {
            type: 'text',
            isRequired: true,
            name: 'Title',
            fieldName: 'body.title',
            onChange: onChange
        },
        {
            type: 'select',
            isRequired: true,
            name: 'Segment',
            fieldName: 'body.segment',
            options: segments,
            onChange: onChange
        },
        {
            type: 'text',
            isRequired: true,
            name: 'Sub Title',
            fieldName: 'body.sub_title',
            onChange: onChange
        },
        {
            type: 'select',
            isRequired: true,
            name: 'Дарахад хийх үйлдэл',
            fieldName: 'notification_page',
            onChange: onChange,
            options: pages
        },
        {
            type: 'text',
            isRequired: true,
            name: 'Body',
            fieldName: 'body.body',
            onChange: onChange
        },
        {
            type: 'text',
            isRequired: true,
            name: 'Нөхцлийн код',
            fieldName: 'body.action',
            onChange: onChange
        },
        {
            type: 'file',
            isRequired: true,
            name: 'Icon',
            fieldName: 'body.icon_url',
            onChange: onChangeImage
        },
        {
            type: 'datetime-local',
            isRequired: true,
            name: 'Явж эхлэх огноо',
            fieldName: 'send_by',
            onChange: onChange
        }
    ]
    return (
        <div className="page-content">
            <Update
                fields={fields}
                title={title}
                context={context}
                onSubmit={onSubmit}
            ></Update>
        </div>
    )
}

export default NotificationDetail
