import React from 'react'
import IPPSservice from 'services/IPPSservice'
import ReactForm from 'ua-react-form'
const { List } = ReactForm

const DataList = ({ datas }) => {
    const fields = [
        { name: 'Нэр', fieldName: 'name' },
        { name: 'Merchant', fieldName: 'merchant.name' },
        { name: 'Logo', fieldName: 'logo', type: 'image' },
        { name: 'Inactive Logo', fieldName: 'inactive_logo', type: 'image' },
        { name: 'Тайлбар', fieldName: 'description' },
        { name: 'Inactive Тайлбар', fieldName: 'inactive_description' },
        { name: 'shortcode', fieldName: 'shortcode' },
        {
            name: 'Collective эсэх',
            fieldName: 'is_collective',
            type: 'boolean'
        },
        { name: 'Харагдах эсэх', fieldName: 'is_show', type: 'boolean' },
        { name: 'Index', fieldName: 'index' },
        { name: 'Group ID', fieldName: 'group_id' }
    ]

    const buttons = [
        {
            color: 'success',
            onClick: () => {
                window.location.href += '/create'
            },
            label: 'Create'
        }
    ]

    const onDelete = async ({ id }) => {
        const result = await IPPSservice.deleteIPPS('badge', id)
        console.log('Delete Result: ', result)
    }
    console.log('badge uud', datas)
    return (
        <List
            fields={fields}
            title={'Badges'}
            width={12}
            datas={datas}
            buttons={buttons}
            editId="_id"
            Delete={{
                fieldName: 'name',
                onDelete: onDelete
            }}
        ></List>
    )
}

export default DataList
