import { postForm } from '../helpers/mcp_api_helper'
export async function upload(data) {
    try {
        let result = await postForm('/v1/file', data)
        return result
    } catch (err) {
        throw err
    }
}

export const getMerchants = async prefix => {
    const result = await request(`/mcp/getMerchants`)
    console.log(result.data)
    return result.data
}

export default {
    upload
}
