import React, { useEffect, useState, useContext } from 'react'
import { useParams, Link, useHistory } from 'react-router-dom'
import { upload } from '../../services/mcp.service'
import { getProduct, updateProduct } from '../../services/coupon.service'
import { formatDatePicker } from '../../helpers/date_helper'

import {
    Row,
    Container,
    Button,
    Card,
    CardBody,
    Col,
    CardTitle
} from 'reactstrap'

import Select from 'react-select'
import AppContext from '../../store/AppContext'

const ProductDetail = props => {
    let history = useHistory()
    const { product_id } = useParams()

    const { notify, merchants, segments } = useContext(AppContext)

    const [image, setImage] = useState()
    const [coverImage, setCoverImage] = useState()
    const [state, setState] = useState()
    const [units, setUnits] = useState([
        {
            label: 'Цаг',
            value: 'hours'
        },
        {
            label: 'Өдөр',
            value: 'days'
        },
        {
            label: 'Сар',
            value: 'months'
        }
    ])

    useEffect(async () => {
        try {
            const result = await getProduct(product_id)
            result.data.expire_after.expire_at = formatDatePicker(
                result.data.expire_after.expire_at
            )
            setState(result.data)

            setImage(result.data.thumbnail)
            setCoverImage(result.data.cover_image)
        } catch (err) {
            console.log(err)
        } finally {
            // setGroupLoading(false)
        }
    }, [])

    const onSubmit = async () => {
        try {
            let result = await updateProduct(product_id, state)
            notify('tr', 'success', `Бүтээгдэхүүнийг амжилттай шинэчиллээ.`)
            history.push(`/coupon/${state.group}`)
        } catch (err) {
            notify('tr', 'danger', `Алдаа гарлаа. ${err.message}`)
            console.log(err)
        }
    }

    const onImageChange = async event => {
        try {
            if (event.target.files && event.target.files[0]) {
                let img = event.target.files[0]
                const data = new FormData()
                data.append('image', img)
                let result = await upload(data)
                if (result.status_code !== 0) {
                    throw new Error(result.status)
                }
                console.log(`uploaded path : ${result.data.path}`)

                setState({
                    ...state,
                    ['thumbnail']: result.data.path
                })
                setImage(result.data.path)
            }
        } catch (err) {
            event.target.value = null
            console.log(err)
        }
    }

    const onCoverChange = async event => {
        try {
            if (event.target.files && event.target.files[0]) {
                let img = event.target.files[0]
                const data = new FormData()
                data.append('image', img)
                let result = await upload(data)
                if (result.status_code !== 0) {
                    throw new Error(result.status)
                }
                console.log(`uploaded path : ${result.data.path}`)

                setState({
                    ...state,
                    ['cover_image']: result.data.path
                })
                setCoverImage(result.data.path)
            }
        } catch (err) {
            event.target.value = null
            console.log(err)
        }
    }

    const onChange = evt => {
        const value = evt.target.value
        setState({
            ...state,
            [evt.target.name]: value
        })
    }

    const onExpireAfterChange = evt => {
        const value = evt.target.value
        setState({
            ...state,
            expire_after: {
                ...state.expire_after,
                [evt.target.name]: value
            }
        })
    }

    const toggleChange = evt => {
        console.log(evt.target.name)
        setState({
            ...state,
            [evt.target.name]: !state[evt.target.name]
        })
    }

    // const merchantChange = (evt) => {
    //     const value = evt.value
    //     setState({
    //         ...state,
    //         ['merchant']: value,
    //     })
    // }

    const unitChange = evt => {
        console.log('unit change called', evt.value)
        const value = evt.value
        setState({
            ...state,
            expire_after: {
                ...state.expire_after,
                unit: evt.value
            }
        })
    }

    if (!state) {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <h3>Купон бүтээгдэхүүн олдсонгүй</h3>
                    </Container>
                </div>
            </React.Fragment>
        )
    }

    const expireAfterFixed = (
        <Row className="mb-3">
            <label
                htmlFor="example-datetime-local-input"
                className="col-md-2 col-form-label"
            >
                Дуусах огноо
            </label>
            <div className="col-md-10">
                <input
                    className="form-control"
                    type="datetime-local"
                    value={state.expire_after && state.expire_after.expire_at}
                    name="expire_at"
                    onChange={onExpireAfterChange}
                    id="example-datetime-local-input"
                />
            </div>
        </Row>
    )

    const expireAfterCalculated = (
        <Row className="mb-3">
            <Col sm="6">
                <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                >
                    Нэгж
                </label>
                <div className="col-md-10">
                    <Select
                        onChange={unitChange}
                        options={units}
                        classNamePrefix="select2-selection"
                        value={units.filter(
                            unit => unit.value === state.expire_after.unit
                        )}
                    />
                </div>
            </Col>
            <Col sm="6">
                <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                >
                    Дүн
                </label>
                <div className="col-md-10">
                    <input
                        className="form-control"
                        type="number"
                        value={
                            state &&
                            state.expire_after &&
                            state.expire_after.qty
                        }
                        name="qty"
                        onChange={onExpireAfterChange}
                    />
                </div>
            </Col>
        </Row>
    )

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="page-title-box d-block align-items-center justify-content-between">
                        <h4 className="mb-1 font-size-18">
                            Бүтээгдэхүүний дэлгэрэнгүй
                        </h4>
                        <div className="page-title-right">
                            <nav className="">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/coupon">Группууд</Link>
                                    </li>
                                    <li
                                        className="active breadcrumb-item"
                                        aria-current="page"
                                    >
                                        <Link to={`/coupon/${state.group}`}>
                                            Групп
                                        </Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    {/* <CardTitle className="h4">Бүтээгдэхүүн дэлгэрэнгүй</CardTitle> */}

                                    <Row>
                                        <Col md="6" sm="12">
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Нэр
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        placeholder="Нэр"
                                                        type="text"
                                                        value={state.name}
                                                        name="name"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Латин Нэр
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        placeholder="Нэр"
                                                        type="text"
                                                        value={state.name_en}
                                                        name="name_en"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Зураг
                                                </label>
                                                <div className="col-md-6">
                                                    <div className="mt-3">
                                                        <Row>
                                                            <Col sm="8">
                                                                <input
                                                                    className="form-control"
                                                                    type="file"
                                                                    id="formFile"
                                                                    onChange={
                                                                        onImageChange
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col sm="4">
                                                                <img
                                                                    src={image}
                                                                    height="70px"
                                                                    width="auto"
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Cover Зураг
                                                </label>
                                                <div className="col-md-6">
                                                    <div className="mt-3">
                                                        <Row>
                                                            <Col sm="8">
                                                                <input
                                                                    className="form-control"
                                                                    type="file"
                                                                    id="formFile"
                                                                    onChange={
                                                                        onCoverChange
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col sm="4">
                                                                <img
                                                                    src={
                                                                        coverImage
                                                                    }
                                                                    height="70px"
                                                                    width="auto"
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Тайлбар
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Тайлбар"
                                                        value={
                                                            state.description
                                                        }
                                                        name="description"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Дараах тайлбар
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Тайлбар"
                                                        value={
                                                            state.post_description
                                                        }
                                                        name="post_description"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Discount Text
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Хямдралын текст"
                                                        value={
                                                            state.discount_text
                                                        }
                                                        name="discount_text"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Худалдан авах лимит
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={
                                                            state.limit_per_user
                                                        }
                                                        name="limit_per_user"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Нийт тоо хэмжээ
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={state.total_qty}
                                                        name="total_qty"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Үлдэгдэл
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={state.qty}
                                                        name="qty"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Button
                                                color="primary"
                                                type="submit"
                                                onClick={onSubmit}
                                            >
                                                Хадгалах
                                            </Button>
                                        </Col>
                                        <Col md="6">
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Cash Price
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={state.cash_price}
                                                        name="cash_price"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Presale Price
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={
                                                            state.presale_price
                                                        }
                                                        name="presale_price"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Point Price
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={
                                                            state.point_price
                                                        }
                                                        name="point_price"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Index
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={state.index}
                                                        name="index"
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Row>

                                            <Row className="mb-3">
                                                <div className="form-check form-check-primary mb-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_active"
                                                        id="isActiveCheck"
                                                        checked={
                                                            state.is_active
                                                        }
                                                        onChange={toggleChange}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="isActiveCheck"
                                                    >
                                                        Идэвхитэй эсэх
                                                    </label>
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <div className="form-check form-check-primary mb-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_show"
                                                        id="isShowCheck"
                                                        checked={state.is_show}
                                                        onChange={toggleChange}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="isShowCheck"
                                                    >
                                                        Харуулах эсэх
                                                    </label>
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <div className="form-check form-check-primary mb-3">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="is_limited"
                                                        id="isLimitedCheck"
                                                        checked={
                                                            state.is_limited
                                                        }
                                                        onChange={toggleChange}
                                                    />

                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="isLimitedCheck"
                                                    >
                                                        Хязгаартай эсэх
                                                    </label>
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <div className="mt-4">
                                                    <h5 className="font-size-14 mb-4">
                                                        Хугацааны төрөл
                                                    </h5>
                                                    <div className="form-check mb-3">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="type"
                                                            id="exampleRadios1"
                                                            value="calculated"
                                                            checked={
                                                                state
                                                                    .expire_after
                                                                    .type ===
                                                                'calculated'
                                                            }
                                                            onChange={
                                                                onExpireAfterChange
                                                            }
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="exampleRadios1"
                                                        >
                                                            Calculated
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="type"
                                                            id="exampleRadios2"
                                                            value="fixed"
                                                            checked={
                                                                state
                                                                    .expire_after
                                                                    .type ===
                                                                'fixed'
                                                            }
                                                            onChange={
                                                                onExpireAfterChange
                                                            }
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="exampleRadios2"
                                                        >
                                                            Fixed
                                                        </label>
                                                    </div>
                                                </div>
                                            </Row>

                                            {state.expire_after.type ===
                                            'calculated'
                                                ? expireAfterCalculated
                                                : expireAfterFixed}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ProductDetail
