import React, { useState } from 'react'
import IPPSservice from 'services/IPPSservice'
import ReactForm from 'ua-react-form'
const { Create } = ReactForm
import _ from 'lodash'

const CreateBadge = () => {
    const [context, setContext] = useState({})

    const onChange = ({ target }) => {
        let con = context
        _.set(con, target.name, target.value)
        setContext(con)
    }

    const onSubmit = async event => {
        try {
            event.preventDefault()
            const result = await IPPSservice.createIPPS('campaign', context)
            console.log(result)
        } catch (err) {
            console.error(err)
        }
    }

    const title = 'Create Badge'

    const fields = [
        {
            type: 'text',
            onChange: onChange,
            name: 'Нэр',
            fieldName: 'name',
            isRequired: true
        },
        {
            type: 'text',
            onChange: onChange,
            name: 'Монгол нэр',
            fieldName: 'name_mn',
            isRequired: true
        },
        {
            type: 'text',
            onChange: onChange,
            name: 'Тайлбар',
            fieldName: 'description',
            isRequired: true
        },
        {
            type: 'text',
            onChange: onChange,
            name: 'Идэвхитэй эсэх',
            fieldName: 'is_active',
            isRequired: true,
            type: 'boolean'
        },
        {
            type: 'datetime-local',
            onChange: onChange,
            name: 'Эхлэх хугацаа',
            fieldName: 'start_at',
            isRequired: true
        },
        {
            type: 'datetime-local',
            onChange: onChange,
            name: 'Дуусах хугацаа',
            fieldName: 'end_at',
            isRequired: true
        }
    ]
    return (
        <div className="page-content">
            <Create
                fields={fields}
                title={title}
                context={context}
                onSubmit={onSubmit}
            ></Create>
        </div>
    )
}

export default CreateBadge
