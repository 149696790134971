// import { Button, Modal } from 'reactstrap'
import { Button, Modal } from 'react-bootstrap'
import { useState } from 'react'

function ConfirmModal({ onConfirm, title, body, confirmText, params }) {
    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)

    const handleShow = e => {
        e.preventDefault()
        setShow(true)
    }
    const button = (
        <Button size="sm" onClick={handleShow}>
            {confirmText}
        </Button>
    )

    return (
        <>
            {button}
            <Modal
                style={{ fontSize: '13px' }}
                show={show}
                onHide={handleClose}
                animation={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="secondary" onClick={handleClose}>
                        Хаах
                    </Button>
                    <Button
                        size="sm"
                        onClick={() => {
                            setShow(false)
                            onConfirm(params)
                        }}
                    >
                        {confirmText}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ConfirmModal
