import React, { useEffect, useState, useContext } from 'react'
import { useParams, Link } from 'react-router-dom'
import segmentService from 'services/segment.service'
import ReactForm from 'ua-react-form'
import XLSX from 'xlsx'

const { Create } = ReactForm

const CreateSegment = () => {
    const [context, setContext] = useState({})

    const onChange = ({ target }) => {
        setContext({ ...context, ...{ [target.name]: target.value } })
    }
    const onFileChange = async ({ target }) => {
        if (target.files && target.files[0]) {
            const f = target.files[0]
            const data = await f.arrayBuffer()
            const workbook = XLSX.read(data)
            if (!workbook) {
                console.log(`there is no workbook. Please upload your workbook`)
                return
            }
            const worksheet = workbook.Sheets[workbook.SheetNames[0]]
            if (!worksheet) {
                console.log(
                    `there is no sheet. Please upload your sheet and it must be the first sheet`
                )
                return
            }
            const rowMajor = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
            let consumers = []
            for (let i = 1; i < rowMajor.length; i += 1) {
                if (!rowMajor[i][0]) continue
                consumers.push(rowMajor[i][0])
            }
            setContext({ ...context, consumers })
            console.log(consumers)
        }
    }

    const onSubmit = async e => {
        e.preventDefault()
        const result = await segmentService.createSegmentWithUsers(context)
    }

    const title = 'Create Segment'

    const fields = [
        {
            type: 'text',
            isRequired: true,
            name: 'Name',
            fieldName: 'name',
            onChange: onChange
        },
        {
            type: 'text',
            isRequired: true,
            name: 'Short Code',
            fieldName: 'short_code',
            onChange: onChange
        },
        {
            type: 'text',
            isRequired: false,
            name: 'Description',
            fieldName: 'description',
            onChange: onChange
        },
        {
            type: 'file',
            isRequired: false,
            name: 'Хэрэглэгчид',
            fieldName: 'users',
            onChange: onFileChange
        }
    ]
    return (
        <div className="page-content">
            <Create
                fields={fields}
                title={title}
                context={context}
                onSubmit={onSubmit}
            ></Create>
        </div>
    )
}

export default CreateSegment
