import { css } from '@emotion/core'
import PulseLoader from 'react-spinners/PulseLoader'
const override = css``
function Loading({ loading }) {
    const style = { marginTop: '20px', textAlign: 'center' }
    return (
        <div style={style}>
            <PulseLoader color={'gray'} css={override} loading={loading} size={20} />
        </div>
    )
}
export default Loading
