import React, { useEffect, useState, useContext } from 'react'
import { get, post } from '../../../helpers/api_helper'
import {
    Button,
    Card,
    CardBody,
    Col,
    Row,
    CardTitle,
    Input,
    CustomInput,
    FormGroup,
    Container
} from 'reactstrap'

import { createPromotion } from 'services/spin.service'
import Select from 'react-select'
import AppContext from '../../../store/AppContext'
import rewardService from 'services/reward.service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'

const CreateSpin = props => {
    const { notify, merchants } = useContext(AppContext)
    const [state, setState] = useState({})
    const [rewards, setRewards] = useState([])

    const onChange = evt => {
        console.log(evt.target.name, evt.target.value)
        setState({
            ...state,
            [evt.target.name]: evt.target.value
        })
        console.log(state)
    }

    const onToggle = evt => {
        setState({
            ...state,
            [evt.target.name]: !state[evt.target.name]
        })
    }
    const onImageChange = async event => {
        try {
            setUploading(true)
            if (event.target.files && event.target.files[0]) {
                let img = event.target.files[0]
                const data = new FormData()
                data.append('image', img)
                let result = await upload(data)
                if (result.status_code !== 0) {
                    throw new Error(result.status)
                }
                console.log(`uploaded path : ${result.data.path}`)

                setState({
                    ...state,
                    ['thumbnail']: result.data.path
                })
                setImage(result.data.path)
            }
        } catch (err) {
            event.target.value = null
            console.log(err)
        } finally {
            setUploading(false)
        }
    }
    const Submit = async () => {
        try {
            console.log(state)
            const result = await createPromotion(state)

            console.log(result)
            notify('tr', 'success', `Reward амжилттай үүсэглээ.`)
        } catch (err) {
            notify('tr', 'danger', `Алдаа гарлаа. ${err.message}`)
        }
    }

    const fetchReward = async () => {
        try {
            let result = (await rewardService.getRewards()).result
            console.log(result)

            console.log(result)
            if (!result) setRewards([])
            else
                setRewards(
                    result.map(reward => {
                        return {
                            label: reward.name,
                            value: reward._id
                        }
                    })
                )
            return result
        } catch (err) {
            throw err
        } finally {
        }
    }

    useEffect(async () => {
        await fetchReward()
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4"></CardTitle>
                                    <CardTitle className="h4">
                                        <a
                                            onClick={() => {
                                                window.location.href = '/spin'
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faArrowCircleLeft}
                                            />{' '}
                                        </a>
                                        Хүрд үүсгэх
                                    </CardTitle>

                                    <div>
                                        <Row className="align-middle mb-0">
                                            <Col md="6" sm="12">
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label"
                                                    >
                                                        Нэр
                                                    </label>
                                                    <div className="col-md-10">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="name"
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label"
                                                    >
                                                        Тайлбар
                                                    </label>
                                                    <div className="col-md-10">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="description"
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label"
                                                    >
                                                        Лого
                                                    </label>
                                                    <div className="col-md-10">
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="logo"
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label"
                                                    >
                                                        Reward
                                                    </label>
                                                    <div className="col-md-10">
                                                        <Input
                                                            name="reward_id"
                                                            type="select"
                                                            onChange={onChange}
                                                        >
                                                            <option
                                                                id={-1}
                                                                value={-1}
                                                            >
                                                                reward сонгох
                                                            </option>
                                                            {rewards.map(
                                                                (
                                                                    reward,
                                                                    id
                                                                ) => {
                                                                    return (
                                                                        <option
                                                                            id={
                                                                                id
                                                                            }
                                                                            value={
                                                                                reward.value
                                                                            }
                                                                        >
                                                                            {
                                                                                reward.label
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            )}
                                                        </Input>
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col md="6" sm="12">
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label"
                                                    >
                                                        Merchant
                                                    </label>
                                                    {merchants && (
                                                        <div className="col-md-10">
                                                            <Input
                                                                name="merchant"
                                                                type="select"
                                                                onChange={
                                                                    onChange
                                                                }
                                                            >
                                                                <option
                                                                    id={-1}
                                                                    value={-1}
                                                                >
                                                                    Мерчант
                                                                    сонгох
                                                                </option>
                                                                {merchants.map(
                                                                    (
                                                                        merchant,
                                                                        id
                                                                    ) => {
                                                                        return (
                                                                            <option
                                                                                id={
                                                                                    id
                                                                                }
                                                                                value={
                                                                                    merchant.value
                                                                                }
                                                                            >
                                                                                {
                                                                                    merchant.label
                                                                                }
                                                                            </option>
                                                                        )
                                                                    }
                                                                )}
                                                            </Input>
                                                        </div>
                                                    )}
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label"
                                                    >
                                                        Эхлэх хугацаа
                                                    </label>
                                                    <div className="col-md-10">
                                                        <input
                                                            className="form-control"
                                                            type="date"
                                                            name="start_at"
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label"
                                                    >
                                                        Дуусах хугацаа
                                                    </label>
                                                    <div className="col-md-10">
                                                        <input
                                                            className="form-control"
                                                            type="date"
                                                            name="end_at"
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label"
                                                    >
                                                        Идэвхитэй эсэх
                                                    </label>
                                                    <div className="col-md-10">
                                                        <Input
                                                            type="checkbox"
                                                            name="is_active"
                                                            onChange={onToggle}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="11"></Col>
                                            <Col>
                                                <Button
                                                    color="primary"
                                                    type="submit"
                                                    onClick={Submit}
                                                >
                                                    Үүсгэх
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreateSpin
