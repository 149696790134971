import React, { useEffect, useState, useContext } from 'react'
import { useParams, Link } from 'react-router-dom'
import IPPSservice from 'services/IPPSservice'
import { upload } from '../../services/mcp.service'
import ReactForm from 'ua-react-form'
const { Update } = ReactForm
import _ from 'lodash'

const Detail = () => {
    const { id } = useParams()
    const [context, setContext] = useState({})

    const onChange = ({ target }) => {
        let con = context
        _.set(con, target.name, target.value)
        setContext(con)
        console.log(con)
    }

    const onFileChange = async ({ target }) => {
        var aaa = target.files
        if (target.files && target.files[0]) {
            const f = target.files[0]
            const data = await f.arrayBuffer()
            const workbook = XLSX.read(data)
            if (!workbook) {
                console.log(`there is no workbook. Please upload your workbook`)
                return
            }
            const worksheet = workbook.Sheets[workbook.SheetNames[0]]
            if (!worksheet) {
                console.log(
                    `there is no sheet. Please upload your sheet and it must be the first sheet`
                )
                return
            }
            const rowMajor = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
            let consumers = []
            for (let i = 1; i < rowMajor.length; i += 1) {
                if (!rowMajor[i][0]) continue
                consumers.push(rowMajor[i][0])
            }
            setContext({ ...context, consumers })
            console.log(consumers)
        }
    }

    const onSubmit = async () => {
        try {
            const result = await IPPSservice.updateIPPS('segment', id, context)
            console.log(result.data)
        } catch (err) {
            console.log(err)
        }
    }

    const getContext = async () => {
        try {
            let notification = await IPPSservice.getOneIPPS('segment', id)
            console.log('Notification', notification)
            setContext(notification)
        } catch (err) {
            console.log(err)
            setContext({})
        }
    }

    useEffect(async () => {
        await getContext()
    }, [])
    const title = 'Update Segment'

    const fields = [
        {
            type: 'text',
            isRequired: true,
            name: 'Name',
            fieldName: 'name',
            onChange: onChange
        },
        {
            type: 'text',
            isRequired: true,
            name: 'Short Code',
            fieldName: 'short_code',
            onChange: onChange
        },
        {
            type: 'text',
            isRequired: false,
            name: 'Description',
            fieldName: 'description',
            onChange: onChange
        },
        {
            type: 'file',
            isRequired: false,
            name: 'Хэрэглэгчид',
            fieldName: 'users',
            onChange: onFileChange
        }
    ]
    return (
        <div className="page-content">
            <Update
                fields={fields}
                title={title}
                context={context}
                onSubmit={onSubmit}
            ></Update>
        </div>
    )
}

export default Detail
