import React, { useEffect, useState } from 'react'
import { Container, Table } from 'reactstrap'
import { getIPPS } from 'services/IPPSservice'
import List from '../../components/Campaign/List'

const Page = () => {
    const [datas, setDatas] = useState([])

    useEffect(async () => {
        try {
            let data = await getIPPS('campaign')
            console.log('badgegang', data)
            setDatas(data)
        } catch (err) {
            console.error(err)
            setDatas([])
        }
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <List datas={datas} />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Page
